import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { put } from "../../../api/client";
import SUBSCRIBERS from "../../../api/services/SUBSCRIBERS";
import Card from "../../../components/cards/Card";
import Loader from "../../../components/ui/Loader";
import Spinner from "../../../components/ui/Spinner";
import styles from "../../../styles/_features/subscriberCard/components/subscriberCard.module.scss";
import { format } from "date-fns";

const SubscriberCard = ({
  data,
  name,
  setName,
  phone,
  setPhone,
  address,
  setAddress,
  email,
  setEmail,
  city,
  setCity,
  zipCode,
  setZipCode,
  status,
  setStatus,
  subType,
  setSubType,
  isEmailVerified,
  emailOTP,
  userName,
  createdAt,
  setUserName,
  password,
  setPassword,
}) => {
  const formdata = new FormData();

  const [loading, setLoading] = useState(false);

  if (!data) {
    return (
      <Card mb={2}>
        <div className={styles.container}>
          <Loader />
        </div>
      </Card>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    formdata.append("id", data._id);
    name && formdata.append("name", name);
    address && formdata.append("address", address);
    city && formdata.append("city", city);
    zipCode && formdata.append("zipCode", zipCode);
    status && formdata.append("status", status);
    subType && formdata.append("subscriberType", subType);
    password && formdata.append("password", password);
    userName && formdata.append("username", userName);

    if (email !== data.email) {
      formdata.append("email", email);
    }
    if (phone !== data.phone) {
      formdata.append("phone", phone);
    }

    setLoading(true);
    try {
      const res = await SUBSCRIBERS.update(formdata);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  const handleRadioChange = (e) => {
    setSubType(e.target.value);
  };

  // const [isEmailVerified, setIsEmailVerified] = useState(null);

  // useEffect(()=>{
  //   const getEmailVerified = async ()=>{
  //     const emailV = get()
  //   }
  // },[])

  return (
    <Card mb={2}>
      <div className={styles.container}>
        <h3>כרטיס מנוי </h3>
        <p>
          {" "}
          אימות מייל:{" "}
          {isEmailVerified === true ? (
            <span style={{ backgroundColor: "#26ab262b" }}>משתמש מאומת</span>
          ) : (
            <span style={{ backgroundColor: "#8d131347" }}>משתמש לא מאומת</span>
          )}
        </p>
        <p>סיסמת אימות אחרונה שנשלחה למייל: {`${emailOTP}`}</p>
        <p> נוצר בתאריך: {format(new Date(createdAt), "dd.MM.yy")}</p>
        <form onSubmit={handleSubmit}>
          <div className={styles.radioField} style={{ marginTop: "10px" }}>
            <label>סוג עסק</label>

            <div className={styles.radioRow}>
              <div className={styles.radio}>
                <input
                  type="radio"
                  value="PRIVATE"
                  name="PRIVATE"
                  checked={subType === "PRIVATE"}
                  onChange={handleRadioChange}
                />
                <label>פרטי</label>
              </div>
              <div className={styles.radio}>
                <input
                  type="radio"
                  value="GARAGE"
                  name="GARAGE"
                  checked={subType === "GARAGE"}
                  onChange={handleRadioChange}
                />
                <label>מוסך</label>
              </div>
              <div className={styles.radio}>
                <input
                  type="radio"
                  value="APPRAISER"
                  name="APPRAISER"
                  checked={subType === "APPRAISER"}
                  onChange={handleRadioChange}
                />
                <label>שמאי</label>
              </div>
              <div className={styles.radio}>
                <input
                  type="radio"
                  value="LEASING_COMPANY"
                  name="LEASING_COMPANY"
                  checked={subType === "LEASING_COMPANY"}
                  onChange={handleRadioChange}
                />
                <label>חברת ליסיג</label>
              </div>
              <div className={styles.radio}>
                <input
                  type="radio"
                  value="INSURANCE_COMPANY"
                  name="INSURANCE_COMPANY"
                  checked={subType === "INSURANCE_COMPANY"}
                  onChange={handleRadioChange}
                />
                <label>חברת ביטוח</label>
              </div>
            </div>
          </div>
          <div className={styles.field}>
            <label>שם הספק / עסק</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <label>טלפון</label>
            <input
              type="number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <label>כתובת</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <label>מייל</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <label>שם משתמש</label>
            <input
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <label>עיר</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>

          {/* <div className={styles.field}>
            <label>מיקוד</label>
            <input
              type="text"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </div> */}

          <div className={styles.field}>
            <label>סטטוס</label>
            <select
              name="סטטוס"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="סטטוס" disabled>
                סטטוס
              </option>
              <option value="ACTIVE">פעיל</option>
              <option value="INACTIVE">לא פעיל</option>
            </select>
          </div>

          <div className={styles.field}>
            <label>סיסמא</label>
            <input
              type="text"
              placeholder="הזנת סיסמא חדשה ללקוח"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit">{loading ? <Spinner /> : "שמור"}</button>
        </form>
      </div>
    </Card>
  );
};

export default SubscriberCard;
