import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import SUB_MODELS from "../api/services/SUB_MODELS";
import Table from "../components/table/Table";
import TableHead from "../components/table/TableHead";
import Modal from "../components/ui/Modal";
import Spinner from "../components/ui/Spinner";
import TableRowSupplier from "../features/subModels/components/TableRowSupplier";
import Layout from "../layouts/Layout";
import styles from "../styles/_screens/models.module.scss";

const SubModels = () => {
  let formData = new FormData();
  const SUB_MODEL_TABLE_HEAD = ["שם", "סטטוס", ""];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subModels, setSubModels] = useState();

  const [name, setName] = useState("");
  const [subModelIcon, setSubModelIcon] = useState("");
  const [subModelIconPreview, setSubModelIconPreview] = useState("");

  const [fetchLoading, setFetchLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);

  // fetch sub models list
  useEffect(() => {
    setCreateSuccess(false);
    const fetchData = async () => {
      try {
        const res = await SUB_MODELS.get();

        if (res.result.length === 0) {
          setSubModels([]);
        } else {
          setSubModels(res.result);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [createSuccess]);

  const addSubModel = async (e) => {
    e.preventDefault();

    formData.append("name", name);
    formData.append("subModelIcon", subModelIcon);

    setCreateLoading(true);
    try {
      const res = await SUB_MODELS.create(formData);
      toast.success(res.data.message);
      setCreateSuccess(true);
      setIsModalOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setCreateLoading(true);
  };

  if (!subModels) {
    return (
      <Layout>
        <div className={styles.container}>loading...</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        {/* TABLE */}
        <Table
          mb={2}
          title="תת דגם"
          buttonText="דגם"
          setIsModalOpen={setIsModalOpen}
        >
          <TableHead data={SUB_MODEL_TABLE_HEAD} />
          <tbody>
            {subModels.length !== 0 &&
              subModels.map((row) => (
                <TableRowSupplier key={row.id} row={row} />
              ))}
          </tbody>
        </Table>

        {/* MODAL */}
        {isModalOpen && (
          <Modal setIsOpen={setIsModalOpen}>
            <div className={styles.modalContent}>
              <h3>דגם</h3>
              <form onSubmit={addSubModel}>
                <input
                  type="file"
                  className={styles.file}
                  name="subModelIcon"
                  placeholder="תת דגם אייקון"
                  onChange={(e) => {
                    setSubModelIcon(e.target.files[0]);
                    setSubModelIconPreview(
                      URL.createObjectURL(e.target.files[0])
                    );
                  }}
                />
                <div className={styles.upload}>
                  <p>תת דגם אייקון</p>
                  <span>+</span>
                </div>
                {subModelIconPreview && (
                  <img
                    src={subModelIconPreview}
                    alt="preview"
                    className={styles.preview}
                  />
                )}
                <input
                  type="text"
                  name="name"
                  placeholder="שם"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <button type="submit">
                  {createLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <img src="/assets/icons/plus.svg" alt="add" />
                      הוספת ספק
                    </>
                  )}
                </button>
              </form>
            </div>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default SubModels;
