import { useNavigate, useLocation } from "react-router-dom";
import { paths } from "../routes/paths";
import styles from "../styles/_layout/header.module.scss";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const classes = {
    main: path === paths.main ? styles.active : "",
    suppliers: path === paths.suppliers ? styles.active : "",
    subscribers: path === paths.subscribers ? styles.active : "",
    partsList: path === paths.partsList ? styles.active : "",
    manufacturers: path === paths.manufacturers ? styles.active : "",
    editText: path === paths.editText ? styles.active : "",
    allRequests: path === paths.allRequests ? styles.active : "",
    supplierAnswers: path === paths.supplierAnswers ? styles.active : "",
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    navigate(paths.login);
  };

  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <div className={styles.right}>
          <div className={styles.logo}>
            <img
              src="/assets/icons/logo_3.svg"
              alt="logo"
              onClick={() => navigate(paths.main)}
              style={{
                cursor: "pointer",
              }}
            />
          </div>

          <nav>
            <ul>
              <li className={classes.main} onClick={() => navigate(paths.main)}>
                ראשי
              </li>

              <li
                className={classes.suppliers}
                onClick={() => navigate(paths.suppliers)}
              >
                רשימת ספקים
              </li>

              <li
                className={classes.subscribers}
                onClick={() => navigate(paths.subscribers)}
              >
                מנויים
              </li>

              <li
                className={classes.partsList}
                onClick={() => navigate(paths.partsList)}
              >
                רשימת חלקים וקטגוריות
              </li>

              <li
                className={classes.manufacturers}
                onClick={() => navigate(paths.manufacturers)}
              >
                יצרנים ודגמים
              </li>
              <li
                className={classes.allRequests}
                onClick={() => navigate(paths.allRequests)}
              >
                בקשות אחרונות במערכת
              </li>
              <li
                className={classes.supplierAnswers}
                onClick={() => navigate(paths.supplierAnswers)}
              >
                חיפוש תשובות ספקים
              </li>
              <li
                className={classes.editText}
                onClick={() => navigate(paths.editText)}
              >
                עריכת תוכן
              </li>
            </ul>
          </nav>
        </div>

        <div className={styles.left}>
          <div className={styles.admin}>
            <img src="/assets/icons/user.svg" alt="user" />
            <p>אזור אישי</p>

            <div className={styles.drop}>
              <ul>
                <li onClick={logout}>
                  <img src="/assets/icons/logout.svg" alt="logout" />
                  התנתקות
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
