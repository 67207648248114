const TableHead = ({ data }) => {
  return (
    <thead>
      <tr>
        {data.map((i) => (
          <th
            key={i}
            style={{
              textAlign: i === "חיפושים" || i === "השהייה" ? "center" : "right",
              paddingLeft: i === "חיפושים" || i === "השהייה" ? "100px" : "0px",
            }}
          >
            {i}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
