import { del, get, post, put } from "../client";
import { CREATE_PART, DELETE_PART, PARTS, UPDATE_PART } from "../routes";

export default {
  get: async (id) => await get(PARTS(id)),

  create: async (data) => await post(CREATE_PART, data),

  delete: async (id) => await del(DELETE_PART(id)),

  update: async (data) => await put(UPDATE_PART, data),
};
