import { format } from "date-fns/esm";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SUBSCRIBERS from "../../../../api/services/SUBSCRIBERS";
import Card from "../../../../components/cards/Card";
import Spinner from "../../../../components/ui/Spinner";
import styles from "../../../../styles/_features/comments/comments.module.scss";

const Comments = ({ subID }) => {
  const [body, setBody] = useState("");
  const [comments, setComments] = useState();

  const [createSuccess, setCreateSuccess] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCreateSuccess(null);
    const fetchData = async () => {
      try {
        const res = await SUBSCRIBERS.get_comments(subID);
        setComments(res.result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [createSuccess]);

  // create comment
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      subscriberId: subID,
      body,
    };

    setLoading(true);
    try {
      const res = await SUBSCRIBERS.create_comment(payload);
      toast.success(res.data.message);
      setCreateSuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <Card>
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <textarea
            name="comment"
            placeholder="הערות"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          ></textarea>
          <button type="submit">{loading ? <Spinner /> : "הוסף הערה"}</button>
        </form>

        <div className={styles.list}>
          <h5>רשימת הערות</h5>

          {comments &&
            comments.map((i) => (
              <div className={styles.comment}>
                <h6>{format(new Date(i.createdAt), "dd/MM/yy")}</h6>
                <p>{i.body}</p>
              </div>
            ))}
        </div>
      </div>
    </Card>
  );
};

export default Comments;
