import { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import REQUESTS from "../api/services/REQUESTS";
import Table from "../components/table/Table";
import TableHead from "../components/table/TableHead";
import TableRowAllRequests from "../features/allRequests/components/TableRowAllRequests";
import Loader from "../components/ui/Loader";
import styles from "../styles/_screens/allRequests.module.scss";
import Filter from "../features/allRequests/components/filter";

const TABLE_HEAD = [
  // "#",
  // date
  "תאריך בקשה",
  // customer type
  // part
  "חלק",
  "סוג לקוח",
  //customername
  "שם לקוח וטלפון",
  // customer city
  "עיר לקוח",
  // vehicle number
  "מס' רכב",
  // degem manoa
  "דגם מנוע",
  // engine
  "מנוע",
  // manufacturer
  "יצרן",
  // model
  "דגם",
  // yop
  "שנה",
  // reply count
  "מגיבים",
  "סוג",
  // delete
  "מחיקה",
  // reply
  "",
  // replies
  "",

  // gear
  // "הילוכים",
  // hp
  // "נפח",
];

const AllRequests = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [requests, setRequests] = useState(null);

  const [deleteRequestSuccess, setDeleteRequestSuccess] = useState(null);
  const [searched, setSearched] = useState(false);

  const [part, setPart] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");
  const [engineType, setEngineType] = useState("");
  const [gearType, setGearType] = useState("");
  const [carNumber, setCarNumber] = useState("");

  // FETCH ALL REQUESTS
  useEffect(() => {
    setDeleteRequestSuccess(true);
    const fetchData = async () => {
      try {
        const res = await REQUESTS.get(
          page,
          size,
          part,
          manufacturer,
          model,
          engineType,
          gearType,
          carNumber
        );
        if (page === 1) {
          setRequests(res.result.requests);
        } else {
          setRequests((prev) => [...prev, ...res.result.requests]);
        }
        setTotalPages(res.result.totalPages);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [page, size, deleteRequestSuccess, searched]);

  const handleSearch = () => {
    setSearched(!searched);
  };

  if (!requests) {
    return (
      <Layout>
        <div className={styles.container}>
          <Loader />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        <h1>בקשות אחרונות במערכת</h1>

        {/* FILTER */}
        <Filter
          part={part}
          setPart={setPart}
          manufacturer={manufacturer}
          setManufacturer={setManufacturer}
          model={model}
          setModel={setModel}
          engineType={engineType}
          setEngineType={setEngineType}
          gearType={gearType}
          setGearType={setGearType}
          carNumber={carNumber}
          setCarNumber={setCarNumber}
          handleSearch={handleSearch}
        />

        {/* TABLE */}
        <Table mb={2} title="בקשות אחרונות במערכת">
          <TableHead data={TABLE_HEAD} />
          <tbody>
            {requests?.length > 0 ? (
              requests.map((row) => (
                <TableRowAllRequests
                  key={row._id}
                  row={row}
                  setDeleteRequestSuccess={setDeleteRequestSuccess}
                  requests={requests}
                />
              ))
            ) : (
              <tr className={styles.emptyRow}>
                <td colSpan={13}>לא נמצאו רשומות</td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* LOAD MORE BUTTON */}
        {page < totalPages && (
          <button
            className={styles.loadMore}
            onClick={() => setPage((prev) => Number(prev) + 1)}
          >
            טען עוד
          </button>
        )}
      </div>
    </Layout>
  );
};

export default AllRequests;
