import { useState } from "react";
import { toast } from "react-toastify";
import MANUFACTURERS from "../../api/services/MANUFACTURERS";
import Spinner from "../../components/ui/Spinner";
import styles from "../../styles/_containers/_manufacturer/manufacturers.module.scss";

const Manufacturers = ({
  manufacturer,
  manufacturers,
  setManufacturer,
  setModels,
  tab,
  setCreateManu,
  setUpdateManu,
  setDeleteManu,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [editValue, setEditValue] = useState("");
  
  // CREATE MANUFACTURER
  const createManufacturer = async (e) => {
    e.preventDefault();

    if (name) {
      const payload = {
        name,
        vehicle: tab,
      };

      setLoading(true);
      try {
        const res = await MANUFACTURERS.create(payload);
        toast.success(res.data.message);
        setCreateManu(true);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  };

  // UPDATE MANUFACTURER
  const updateManufacturer = async (id) => {
    const payload = {
      id,
      name: editValue,
      vehicle: tab,
    };

    try {
      const res = await MANUFACTURERS.update(payload);
      toast.success(res.data.message);
      setUpdateManu(true);
      setIsEdit(null);
      setEditValue(null);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  // DELETE MANUFACTURER
  const deleteManufacturer = async (id) => {
    try {
      const res = await MANUFACTURERS.delete(id);
      toast.success(res.data.message);
      setDeleteManu(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>יצרנים</p>

      {/* add manufacturer */}
      <form onSubmit={createManufacturer}>
        <input
          type="text"
          placeholder="הזן שם"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button type="submit" disabled={!name}>
          {loading ? (
            <div className={styles.spinner}>
              <Spinner />
            </div>
          ) : (
            <>
              <img src="/assets/icons/plus.svg" alt="add" />
              הוספה
            </>
          )}
        </button>
      </form>

      {/* list of manufacturers */}
      <h4>רשימת יצרנים</h4>
      <div className={styles.list}>
      {manufacturers.length > 0 &&
  manufacturers
    .sort((a, b) => a.name.localeCompare(b.name))  // Add this line to sort the manufacturers by name
    .map((i) => (
            <Item
              id={i.id}
              key={i.id}
              name={i.name}
              onClick={() => {
                setManufacturer(i);
                setModels(i.models);
              }}
              manufacturer={manufacturer}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              editValue={editValue}
              setEditValue={setEditValue}
              updateManufacturer={updateManufacturer}
              deleteManufacturer={deleteManufacturer}
            />
          ))}
      </div>
    </div>
  );
};

export default Manufacturers;

const Item = ({
  id,
  name,
  onClick,
  manufacturer,
  setIsEdit,
  isEdit,
  editValue,
  setEditValue,
  updateManufacturer,
  deleteManufacturer,
}) => {
  return (
    <div className={styles.item} key={id}>
      {
        // if edit true then show input field
        isEdit === id ? (
          <input
            className={styles.editField}
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
        ) : (
          // if edit false then show the item name
          <div
            className={`${styles.box} ${
              manufacturer && manufacturer.id === id ? styles.active : ""
            }`}
            onClick={onClick}
          >
            <span>{">"}</span>
            <p>{name}</p>
          </div>
        )
      }

      {/* delete icon */}
      <img
        src="/assets/icons/trash.svg"
        alt="delete"
        className={styles.delete}
        onClick={() => deleteManufacturer(id)}
      />

      {/* if edit true then show save icon */}
      {isEdit === id ? (
        <img
          src="/assets/icons/save.png"
          alt="edit"
          className={styles.save}
          onClick={() => updateManufacturer(id)}
        />
      ) : (
        // if edit false then show edit icon
        <img
          src="/assets/icons/write.svg"
          alt="edit"
          className={styles.edit}
          onClick={() => {
            setIsEdit(id);
            setEditValue(name);
          }}
        />
      )}
    </div>
  );
};
