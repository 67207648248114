import styles from "../../../styles/_features/partsList/components/tabs.module.scss";

const Tabs = ({ tab, setTab, setCategory }) => {
  return (
    <div className={styles.container}>
      <div
        className={`${styles.tab} ${tab === "CAR" ? styles.active : ""}`}
        onClick={() => {
          setTab("CAR");
          setCategory(null);
        }}
      >
        <img src="/assets/icons/car.svg" alt="car" />
        <p>רכב</p>
      </div>

      <div
        className={`${styles.tab} ${tab === "TRUCK" ? styles.active : ""}`}
        onClick={() => {
          setTab("TRUCK");
          setCategory(null);
        }}
      >
        <img src="/assets/icons/truck.svg" alt="truck" />
        <p>משאית</p>
      </div>

      <div
        className={`${styles.tab} ${tab === "BIKE" ? styles.active : ""}`}
        onClick={() => {
          setTab("BIKE");
          setCategory(null);
        }}
      >
        <img src="/assets/icons/bike.svg" alt="bike" />
        <p>אופנוע</p>
      </div>
    </div>
  );
};

export default Tabs;
