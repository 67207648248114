import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SUPPLIERS from "../api/services/SUPPLIERS";
import Search from "../components/forms/Search";
import Table from "../components/table/Table";
import TableHead from "../components/table/TableHead";
import Loader from "../components/ui/Loader";
import Modal from "../components/ui/Modal";
import Spinner from "../components/ui/Spinner";
import TableRowSupplier from "../features/suppliers/components/TableRowSupplier";
import Layout from "../layouts/Layout";
import { paths } from "../routes/paths";
import styles from "../styles/_screens/suppliers.module.scss";
import { isTokenExpired } from "../utils/isTokenExpired";

const Suppliers = () => {
  let formdata = new FormData();

  const navigate = useNavigate();

  const token = localStorage.getItem("authToken");

  // redirect to login if no token.
  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [token]);

  const SUPPLIER_TABLE_HEAD = [
    // "#",
    "שם הספק",
    "טלפון",
    "כתובת",
    "מייל",
    "עיר",
    "סטטוס",
    "השהייה",
    "",
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [suppliers, setSuppliers] = useState();

  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(200);

  const [totalRecords, setTotalRecords] = useState(0);

  const [isUpdated, setIsUpdated] = useState(false);

  const [createSuccess, setCreateSuccess] = useState(true);

  const [searchValue, setSearchValue] = useState("");

  const [invalidFields, setInvalidFields] = useState([]);

  // modal
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [status, setStatus] = useState("ACTIVE");

  const [deleteSuccess, setDeleteSuccess] = useState(null);

  const [isSupDltModalOpen, setIsSupDltModalOpen] = useState(false);

  const [dltSupLoading, setDltSupLoading] = useState(false);

  // errors
  const [nameError, setNameError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  // check if email valid
  const isValidEmail = (email) => {
    const emailRegExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegExp.test(email);
  };

  // fetch suppliers list
  useEffect(() => {
    setCreateSuccess(null);
    setDeleteSuccess(null);
    const fetchData = async () => {
      try {
        const res = await SUPPLIERS.get(page, limit);
        setTotalRecords(res.result.totalSuppliers);

        if (res.result.length === 0) {
          setSuppliers([]);
        } else {
          let sortedItems = res.result.records.sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          setSuppliers(sortedItems);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        console.log(error.response.status);
        isTokenExpired(
          error.response.data.status,
          error.response.data.message,
          navigate
        );
      }
    };

    fetchData();
  }, [isUpdated, createSuccess, deleteSuccess, limit]);

  // search
  const searchSubmit = async (e) => {
    e.preventDefault();

    // if (searchValue.trim()) {
      try {
        const res = await SUPPLIERS.search(searchValue);

        if (res.result.length === 0) {
          // console.log(res.result.length);
          setSuppliers([]);
        } else {
          setSuppliers(res.result.records);
        }
        setSearchValue("");
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        // console.log(error.response.status);
        isTokenExpired(
          error.response.data.status,
          error.response.data.message,
          navigate
        );
      }
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      setNameError("זהו שדה חובה");
    }
    if (!phone) {
      setPhoneError("זהו שדה חובה");
    }
    if (!address) {
      setAddressError("זהו שדה חובה");
    }
    if (!email) {
      setEmailError("זהו שדה חובה");
    }
    if (!password) {
      setPasswordError("זהו שדה חובה");
    }
    if (!isValidEmail(email)) {
      setEmailError("אימייל שגוי");
    }
    if (phone.length < 10) {
      setPhoneError("מספר הטלפון לא תקין");
    }

    if (
      name &&
      email &&
      phone &&
      address &&
      password &&
      isValidEmail(email) &&
      phone.length >= 10
    ) {
      const payload = {
        name,
        phone,
        email,
        address,
        password,
        // status,
      };

      try {
        const res = await SUPPLIERS.create(payload);
        toast.success(res.data.message);
        setCreateSuccess(true);
        setIsModalOpen(false);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        setIsModalOpen(false);
      }
    }
  };

  // delete supplier
  const deleteSupplier = async (id) => {
    formdata.append("id", id);
    formdata.append("isDeleted", true);

    setDltSupLoading(true);
    try {
      const res = await SUPPLIERS.update(formdata);
      toast.success(res.data.message);
      setDeleteSuccess(true);
      setIsSupDltModalOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setDltSupLoading(false);
  };

  if (!suppliers) {
    return (
      <Layout>
        <div className={styles.container}>
          <Loader />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        {/* SEARCH */}
        <Search
          placeholder="חיפוש ספק"
          mb={2}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          handleSubmit={searchSubmit}
        />

        {/* TABLE */}
        <Table
          mb={2}
          title={`רשימת ספקים (${totalRecords})`}
          buttonText="הוספת ספק"
          exportBtnText="ייצוא"
          setIsModalOpen={setIsModalOpen}
        >
          <TableHead data={SUPPLIER_TABLE_HEAD} />
          <tbody>
            {suppliers.length > 0 ? (
              suppliers.map((row) => (
                <TableRowSupplier
                  key={row._id}
                  row={row}
                  setIsUpdated={setIsUpdated}
                  isUpdated={isUpdated}
                  setIsSupDltModalOpen={setIsSupDltModalOpen}
                />
              ))
            ) : (
              <tr className={styles.emptyRow}>
                <td colSpan={7}>לא נמצאו רשומות</td>
              </tr>
            )}
          </tbody>
        </Table>

        {limit < totalRecords && (
          <button
            className={styles.loadMore}
            onClick={() => setLimit((prev) => Number(prev) + 50)}
          >
            טען עוד
          </button>
        )}

        {/* MODAL */}
        {isModalOpen && (
          <Modal setIsOpen={setIsModalOpen}>
            <div
              className={`${styles.modalContent} ${
                invalidFields ? styles.invalid : ""
              }`}
            >
              <h3>הוספת ספק</h3>
              <form onSubmit={handleSubmit}>
                <div className={styles.field}>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setNameError("");
                      setPhoneError("");
                      setEmailError("");
                      setAddressError("");
                      setName(e.target.value);
                    }}
                    placeholder="שם הספק"
                  />
                  {nameError && (
                    <small className={styles.error}>{nameError}</small>
                  )}
                </div>

                <div className={styles.field}>
                  <input
                    type="text"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="סיסמה"
                  />
                  {passwordError && (
                    <small className={styles.error}>{passwordError}</small>
                  )}
                </div>

                <div className={styles.field}>
                  <input
                    type="number"
                    value={phone}
                    onChange={(e) => {
                      setNameError("");
                      setPhoneError("");
                      setEmailError("");
                      setAddressError("");
                      setPhone(e.target.value);
                    }}
                    placeholder="טלפון"
                  />
                  {phoneError && (
                    <small className={styles.error}>{phoneError}</small>
                  )}
                </div>

                <div className={styles.field}>
                  <input
                    type="text"
                    value={address}
                    placeholder="כתובת"
                    onChange={(e) => {
                      setNameError("");
                      setPhoneError("");
                      setEmailError("");
                      setAddressError("");
                      setAddress(e.target.value);
                    }}
                  />
                  {addressError && (
                    <small className={styles.error}>{addressError}</small>
                  )}
                </div>

                <div className={styles.field}>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setNameError("");
                      setPhoneError("");
                      setEmailError("");
                      setAddressError("");
                      setEmail(e.target.value);
                    }}
                    placeholder="מייל"
                  />
                  {emailError && (
                    <small className={styles.error}>{emailError}</small>
                  )}
                </div>
                <button type="submit">
                  <img src="/assets/icons/plus.svg" alt="add" />
                  הוספת ספק
                </button>
              </form>
            </div>
          </Modal>
        )}

        {/* DELETE SUPPLIER MODAL */}
        {isSupDltModalOpen && (
          <Modal setIsOpen={setIsSupDltModalOpen}>
            <div className={styles.modalContainer}>
              <h1>האם אתה בטוח?</h1>
              <p>אתה בטוח שאתה רוצה למחוק את המשתמש?</p>

              <div className={styles.btnContainer}>
                <button onClick={() => setIsSupDltModalOpen(false)}>לא</button>
                <button onClick={() => deleteSupplier(isSupDltModalOpen)}>
                  {dltSupLoading ? <Spinner /> : "כן"}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default Suppliers;
