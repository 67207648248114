import styles from "../../styles/_components/label.module.scss";

const Label = ({ text, variant }) => {
  return (
    <div
      className={`${styles.container} ${
        text === "ACTIVE" ? styles.green : styles.red
      }`}
    >
      {text === "ACTIVE" ? "פעיל" : "לא פעיל"}
    </div>
  );
};

export default Label;
