// auth
const LOGIN = `/admin/login`;

const REGISTER = `/admin/signup`;

// == MODELS ==

const MODELS = `/admin/model`;

const SUB_MODELS = `/admin/submodel`;

const UPDATE_MODEL = `/admin/models`;

// == MANUFACTURERS ==

const MANUFACTURERS = (vehicle) => `/admin/manufacturers?vehicle=${vehicle}`;

const CREATE_MANUFACTURER = `/admin/manufacturer/create`;

const UPDATE_MANUFACTURER = `/admin/manufacturer/update`;

const DELETE_MANUFACTURER = (id) => `/admin/manufacturer/delete/${id}`;

// == CATEGORIES ==

const CATEGORIES = `/admin/categories?isAdmin=true`;

const CREATE_CATEGORY = `/admin/category/create`;

const DELETE_CATEGORY = (id) => `/admin/category/delete/${id}`;

const UPDATE_CATEGORY = `/admin/category/update`;

// == PARTS ==

const PARTS = (id) => `/admin/parts/${id}`;

const CREATE_PART = `/admin/part/create`;

const DELETE_PART = (id) => `/admin/part/delete/${id}`;

const UPDATE_PART = `/admin/part/update`;

// == SUPPLIERS ==

const SUPPLIERS = (page, size, name) =>
  `/admin/suppliers?page=${page}&size=${size}&name=${name}`;

const CREATE_SUPPLIER = `/admin/supplier`;

const UPDATE_SUPPLIER = `/admin/supplier`;

const SEARCH_SUPPLIER = (name) => `/admin/suppliers?name=${name}`;

const SUPPLIER_BY_ID = (id) => `/admin/supplier/${id}`;

const SUPPLIER_COMMENTS = (id) => `/admin/supplier/comments/${id}`;

const CREATE_SUPPLIER_COMMENT = `/admin/supplier/comment`;

const SUPPLIER_PARTS = (supplier, category) =>
  `/admin/supplierparts?supplierId=${supplier}&categoryId=${category}`;

const CREATE_SUPPLIER_PARTS = `/admin/supplier/parts`;

const REPLY_REQUEST = `/admin/replyAsSupplier`;

// SUBSCRIBER

const SUBSCRIBERS = (page, size) =>
  `/admin/subscribers?page=${page}&size=${size}`;

const UPDATE_SUBSCRIBER = `/admin/subscriber`;

const SEARCH_SUBSCRIBER = (name) => `/admin/subscribers?name=${name}`;

const SUBSCRIBER_BY_ID = (id) => `/admin/subscriber/${id}`;

const SUBSCRIBER_COMMENTS = (id) => `/admin/subscriber/comments/${id}`;

const PAYMENT_HISTORY = (id) => `/admin/subscriberpayments/${id}`;

const SUBSCRIBE_USER = `/admin/subscribe`;

const DELETE_SUBSCRIPTION = (id) => `/admin/cancelManualSubscription/${id}`;

const CREATE_SUBSCRIBER_COMMENT = `/admin/subscriber/comment`;

const STATS = `/admin/stats`;

const GET_SUPPLIER_PARTS = (id) => `/admin/supplierParts/${id}`;

const GET_SUPPLIER_MANUFACTURERS = (id, vehicle) =>
  `/admin/supplierManufacturers/${id}?vehicle=${vehicle}`;

const CREATE_SUPPLIER_MANUFACTURER = `/admin/supplier/manufacturer`;

const CONTENT = `/admin/dynamicContent`;

const GET_ALL_REQUESTS = (
  page,
  size,
  part,
  manufacturer,
  model,
  engineType,
  gearType,
  carLicenseNumber
) =>
  `/admin/subscriberRequests?page=${page}&size=${size}&part=${part}&manufacturer=${manufacturer}&model=${model}&engineType=${engineType}&gearType=${gearType}&carLicenseNumber=${carLicenseNumber}`;

const GET_REPLY_COUNT_ALL_REQUESTS = (id) => `/admin/requestRepliesCount/${id}`;

const DELETE_REQUEST_ALL_REQUESTS = (id) => `/admin/deleteRequest/${id}`;

const GET_REPLIES = (id, page, size) =>
  `/admin/subscriberRequestReplies/${id}?page=${page}&size=${size}`;

// others
const ALL_COUNTRIES = `/all`;

export {
  LOGIN,
  REGISTER,
  MODELS,
  SUB_MODELS,
  MANUFACTURERS,
  CREATE_MANUFACTURER,
  UPDATE_MANUFACTURER,
  DELETE_MANUFACTURER,
  UPDATE_MODEL,
  CATEGORIES,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  PARTS,
  CREATE_PART,
  DELETE_PART,
  UPDATE_PART,
  SUPPLIERS,
  CREATE_SUPPLIER,
  UPDATE_SUPPLIER,
  SEARCH_SUPPLIER,
  SUPPLIER_BY_ID,
  SUPPLIER_COMMENTS,
  CREATE_SUPPLIER_COMMENT,
  SUPPLIER_PARTS,
  CREATE_SUPPLIER_PARTS,
  REPLY_REQUEST,
  SUBSCRIBERS,
  SUBSCRIBER_BY_ID,
  UPDATE_SUBSCRIBER,
  SEARCH_SUBSCRIBER,
  SUBSCRIBER_COMMENTS,
  CREATE_SUBSCRIBER_COMMENT,
  PAYMENT_HISTORY,
  SUBSCRIBE_USER,
  DELETE_SUBSCRIPTION,
  STATS,
  GET_SUPPLIER_PARTS,
  CONTENT,
  GET_SUPPLIER_MANUFACTURERS,
  CREATE_SUPPLIER_MANUFACTURER,
  GET_ALL_REQUESTS,
  GET_REPLY_COUNT_ALL_REQUESTS,
  DELETE_REQUEST_ALL_REQUESTS,
  GET_REPLIES,
  ALL_COUNTRIES,
};
