import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import CONTENT from "../api/services/CONTENT";
import Loader from "../components/ui/Loader";
import Spinner from "../components/ui/Spinner";
import Layout from "../layouts/Layout";
import styles from "../styles/_screens/editText.module.scss";

const EditText = () => {
  const [fields, setFields] = useState({
    promoText: "",
    searchCardTitle: "",
    searchCardDescription: "",
    aboutCardTitle: "",
    aboutCardDescription: "",
    featureCard1Title: "",
    featureCard1Body: "",
    featureCard2Title: "",
    featureCard2Body: "",
    featureCard3Title: "",
    featureCard3Body: "",
    featureCard4Title: "",
    featureCard4Body: "",
  });

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  // fetch data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await CONTENT.get();
        setFields(res.result);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const changeHandler = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  // submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = fields;

    delete fields.id;
    delete fields._id;
    delete fields.createdAt;
    delete fields.updatedAt;
    delete fields.__v;

    function isObjectPropertiesNotEmpty(obj) {
      for (const prop in obj) {
        if (!obj[prop]) {
          return false;
        }
      }
      return true;
    }

    if (isObjectPropertiesNotEmpty(fields)) {
      setLoading2(true);
      try {
        const res = await CONTENT.create(payload);
        console.log(res);
        toast.success(res.data.message);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
      setLoading2(false);
    }
  };

  if (loading) {
    return (
      <Layout>
        <div className={styles.container}>
          <Loader />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1>עריכת תוכן</h1>

          <form onSubmit={handleSubmit}>
            {/* promo text */}
            <div className={styles.field}>
              <label>טקסט קידום</label>
              <img 
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}      
              src="/assets/images/1.png" />
              <input
                type="text"
                name="promoText"
                value={fields.promoText}
                onChange={changeHandler}
              />
                            <label>כותרת לסקשן חיפוש</label>
              <img
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}     
              src="/assets/images/2.png" />
              <input
                type="text"
                name="searchCardTitle"
                value={fields.searchCardTitle}
                onChange={changeHandler}
              />
            </div>

            {/* search card title */}
            <div className={styles.field}>

            </div>

            {/* search card description */}
            <div className={styles.field}>
              <label>תיאור סקשן חיפוש</label>
              <img 
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}      
              src="/assets/images/3.png" />
              <textarea
                name="searchCardDescription"
                value={fields.searchCardDescription}
                onChange={changeHandler}
              ></textarea>
            </div>

            {/* about card title */}
            <div className={styles.field}>
              <label>כותרת אודותינו</label>
              <img
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}     
              src="/assets/images/4.png" />
              <input
                type="text"
                name="aboutCardTitle"
                value={fields.aboutCardTitle}
                onChange={changeHandler}
              />
            </div>

            {/* about card description */}
            <div className={styles.field}>
              <label>תיאור אודותינו</label>
              <img
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}     
              src="/assets/images/5.png" />
              <textarea
                name="aboutCardDescription"
                value={fields.aboutCardDescription}
                onChange={changeHandler}
              ></textarea>
            </div>

            {/* feature card 1 title */}
            <div className={styles.field}>
              <label>כותרת הסבר ראשונה</label>
              <img
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}     
              src="/assets/images/6.png" />
              <input
                type="text"
                name="featureCard1Title"
                value={fields.featureCard1Title}
                onChange={changeHandler}
              />
            </div>

            {/* feature card 1 body */}
            <div className={styles.field}>
              <label>תיאור הסבר ראשונה</label>
              <img
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}     
              src="/assets/images/7.png" />
              <textarea
                name="featureCard1Body"
                value={fields.featureCard1Body}
                onChange={changeHandler}
              ></textarea>
            </div>

            {/* feature card 2 title */}
            <div className={styles.field}>
              <label>כותרת הסבר שניה</label>
              <img
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}     
              src="/assets/images/8.png" />
              <input
                type="text"
                name="featureCard2Title"
                value={fields.featureCard2Title}
                onChange={changeHandler}
              />
            </div>

            {/* feature card 2 body */}
            <div className={styles.field}>
              <label>תיאור הסבר שניה</label>
              <img
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}     
              src="/assets/images/9.png" />
              <textarea
                name="featureCard2Body"
                value={fields.featureCard2Body}
                onChange={changeHandler}
              ></textarea>
            </div>

            {/* feature card 3 title */}
            <div className={styles.field}>
              <label>כותרת הסבר שלישית</label>
              <img
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}     
              src="/assets/images/10.png" />
              <input
                type="text"
                name="featureCard3Title"
                value={fields.featureCard3Title}
                onChange={changeHandler}
              />
            </div>

            {/* feature card 3 body */}
            <div className={styles.field}>
              <label>תיאור הסבר שלישית</label>
              <img
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}     
              src="/assets/images/11.png" />
              <textarea
                name="featureCard3Body"
                value={fields.featureCard3Body}
                onChange={changeHandler}
              ></textarea>
            </div>

            {/* feature card 4 title */}
            <div className={styles.field}>
              <label>כותרת הסבר רביעי</label>
              <img
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}     
              src="/assets/images/12.png" />
              <input
                type="text"
                placeholder="Text 6"
                name="featureCard4Title"
                value={fields.featureCard4Title}
                onChange={changeHandler}
              />
            </div>

            {/* feature card 4 body */}
            <div className={styles.field}>
              <label>תיאור הסבר רביעי</label>
              <img
              style={{
                height: '250px',
                objectFit: 'cover',
                marginBottom: '10px'
              }}     
              src="/assets/images/13.png" />
              <textarea
                name="featureCard4Body"
                value={fields.featureCard4Body}
                onChange={changeHandler}
              ></textarea>
            </div>

            <button>{loading2 ? <Spinner /> : "שמור"}</button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default EditText;
