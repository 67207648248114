import { useNavigate } from "react-router-dom";
import Label from "../../../components/ui/Label";
import SUBSCRIBERS from "../../../api/services/SUBSCRIBERS";

const TableRowSubscriber = ({ row, setIsSubDltModalOpen }) => {
  const navigate = useNavigate();

  const { _id, name, phone, totalSearches, email, status, lastSubscription } =
    row;

  return (
    <tr>
      {/* <td style={{ width: "50px" }}>{_id}</td> */}
      <td style={{ width: "100px" }}>{name}</td>
      <td style={{ width: "100px" }}>{phone}</td>
      <td style={{ width: "150px", textAlign: "center", paddingLeft: "100px" }}>
        {totalSearches}
      </td>
      <td style={{ width: "100px" }}>{email}</td>
      <td style={{ width: "100px" }}>
        {lastSubscription ? lastSubscription.plan.name : "-"}
      </td>

      <td style={{ width: "50px" }}>
        <Label text={status} />
      </td>
      <td style={{ width: "80px" }}>
        <img
          src="/assets/icons/share.svg"
          alt="share"
          onClick={() => navigate(`/dashboard/subscriber-card/${_id}`)}
        />
        <img
          src="/assets/icons/edit.svg"
          alt="edit"
          onClick={() => navigate(`/dashboard/subscriber-card/${_id}`)}
        />
        <img
          src="/assets/icons/trash_2.svg"
          alt="delete"
          className="tableDeleteIcon"
          onClick={() => setIsSubDltModalOpen(_id)}
        />
      </td>
    </tr>
  );
};

export default TableRowSubscriber;
