import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import PARTS from "../../api/services/PARTS";
import styles from "../../styles/_containers/partsList/parts.module.scss";

const Parts = ({ category, parts, setParts }) => {

  const [name, setName] = useState("");
  const [editValue, setEditValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [createPartSuccess, setCreatePartSuccess] = useState(null);
  const [deletePartSuccess, setDeletePartSuccess] = useState(null);
  const [updatePartSuccess, setUpdatePartSuccess] = useState(null);

  // FETCH PARTS
  useEffect(() => {
    setCreatePartSuccess(null);
    setDeletePartSuccess(null);
    setUpdatePartSuccess(null);
    const fetchCategories = async () => {
      try {
        const res = await PARTS.get(category._id);
        setParts(res.result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategories();
  }, [category, createPartSuccess, deletePartSuccess, updatePartSuccess]);

  // CREATE PART
  const createPart = async (e) => {
    e.preventDefault();

    if (name) {
      const payload = {
        name,
        categoryId: category._id,
      };

      try {
        const res = await PARTS.create(payload);
        toast.success(res.data.message);
        setCreatePartSuccess(true);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
      setName("");
    }
  };

  // DELETE PART
  const deletePart = async (id) => {
    try {
      const res = await PARTS.delete(id);
      toast.success(res.data.message);
      setDeletePartSuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  // UPDATE PART
  const updatePart = async (id) => {
    const payload = {
      id,
      name: editValue,
    };

    try {
      const res = await PARTS.update(payload);
      toast.success(res.data.message);
      setUpdatePartSuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setIsEdit(false);
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>הוספת חלק</p>

      {/* add part */}
      <form onSubmit={createPart}>
        <input
          type="text"
          placeholder="הוספת חלק"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button type="submit" disabled={!name}>
          <img src="/assets/icons/plus.svg" alt="add" />
          הוספת חלק
        </button>
      </form>

      {/* list of parts */}
      <h4>חלקים מקטגורית : {category.name}</h4>
      <ul className={styles.list}>
        {parts &&
          parts.map((i) => (
            <li className={styles.item} key={i._id}>
              {
                // show input on edit
                isEdit === i._id ? (
                  isEdit ? (
                    <input
                      type="text"
                      className={styles.editField}
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                    />
                  ) : (
                    // show name otherwise
                    <p>{i.name}</p>
                  )
                ) : (
                  <p>{i.name}</p>
                )
              }
              <div className={styles.icons}>
                <img
                  src="/assets/icons/trash.svg"
                  alt="delete"
                  className={styles.delete}
                  onClick={() => deletePart(i._id)}
                />
                {isEdit === i._id ? (
                  isEdit ? (
                    <img
                      src="/assets/icons/save.png"
                      alt="edit"
                      className={styles.save}
                      onClick={() => updatePart(i._id)}
                    />
                  ) : (
                    <img
                      src="/assets/icons/write.svg"
                      alt="edit"
                      className={styles.edit}
                      onClick={() => {
                        setIsEdit(i._id);
                        setEditValue(i.name);
                      }}
                    />
                  )
                ) : (
                  <img
                    src="/assets/icons/write.svg"
                    alt="edit"
                    className={styles.edit}
                    onClick={() => {
                      setIsEdit(i._id);
                      setEditValue(i.name);
                    }}
                  />
                )}
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Parts;
