import styles from "../../../styles/_features/allRequests/filter.module.scss";

const Filter = ({
  part,
  setPart,
  manufacturer,
  setManufacturer,
  model,
  setModel,
  engineType,
  setEngineType,
  gearType,
  setGearType,
  carNumber,
  setCarNumber,
  handleSearch,
}) => {
  return (
    <div className={styles.container}>
      {/* PART */}
      <input
        type="text"
        value={part}
        onChange={(e) => setPart(e.target.value)}
        placeholder="שם החלק"
      />
      {/* MANUFACTURER */}
      <input
        type="text"
        value={manufacturer}
        onChange={(e) => setManufacturer(e.target.value)}
        placeholder="יצרן"
      />
      {/* MODEL */}
      <input
        type="text"
        value={model}
        onChange={(e) => setModel(e.target.value)}
        placeholder="מודל"
      />
      {/* ENGINE TYPE */}
      <select
        value={engineType}
        onChange={(e) => setEngineType(e.target.value)}
      >
        <option value="" selected disabled>
          דגם מנוע
        </option>
        <option value="GASOLINE">בנזין</option>
        <option value="TURBO GASOLINE">טורבו בנזין</option>
        <option value="DIESEL">דיזל</option>
        <option value="TURBO DIESEL">טורבו דיזל</option>
        <option value="HYBRID">היברידי</option>
        <option value="ELECTRICAL POWER">חשמלי</option>
        <option value="ELECTRICITY/GASOLINE">חשמלי / בנזין</option>
        <option value="בנזין">בנזין</option>
        <option value="GAPAM">גפ"מ</option>
      </select>
      {/* GEAR TYPE */}
      <select value={gearType} onChange={(e) => setGearType(e.target.value)}>
        <option value="" selected disabled>
          גיר
        </option>
        <option value="AUTOMATIC">אוטומטי</option>
        <option value="MANUAL">ידני</option>
        <option value="TIPTONIC">טיפטרוניק</option>
        <option value="ROBOTIC">רובוטי</option>
      </select>
      {/* CAR NUMBER */}
      <input
        type="text"
        value={carNumber}
        onChange={(e) => setCarNumber(e.target.value)}
        placeholder="מספר רכב"
      />

      <button onClick={handleSearch}>חיפוש</button>
    </div>
  );
};

export default Filter;
