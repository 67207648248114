import { useState } from "react";
import { toast } from "react-toastify";
import CATEGORIES from "../../api/services/CATEGORIES";
import styles from "../../styles/_containers/partsList/categories.module.scss";

const Categories = ({
  filteredCategories,
  category,
  setCategory,
  tab,
  setCreateCategorySuccess,
  setDeleteCategorySuccess,
  setUpdateCategorySuccess,
  setParts,
}) => {
  const [name, setName] = useState("");
  const [editValue, setEditValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  // create category
  const createCategory = async (e) => {
    e.preventDefault();

    if (name) {
      const payload = {
        name,
        vehicle: tab,
      };

      try {
        const res = await CATEGORIES.create(payload);
        toast.success(res.data.message);
        setCreateCategorySuccess(true);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
      setName("");
    }
  };

  // delete category
  const deleteCategory = async (id) => {
    try {
      const res = await CATEGORIES.delete(id);
      toast.success(res.data.message);
      setDeleteCategorySuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  // update category
  const updateCategory = async (id) => {
    const payload = {
      id,
      name: editValue,
    };

    try {
      const res = await CATEGORIES.update(payload);
      console.log(res);
      toast.success(res.data.message);
      setUpdateCategorySuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setIsEdit(false);
  };

  const updateCategoryVisibility = async (id, isDeleted) => {
    const payload = {
      id,
      isDeleted,
    };

    try {
      const res = await CATEGORIES.update(payload);
      console.log(res);
      toast.success(res.data.message);
      setUpdateCategorySuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setIsEdit(false);
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>הוספת קטגוריה</p>

      {/* add category */}
      <form onSubmit={createCategory}>
        <input
          type="text"
          placeholder="שם הקטגוריה"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button type="submit" disabled={!name}>
          <img src="/assets/icons/plus.svg" alt="add" />
          הוספה
        </button>
      </form>

      {/* list of categories */}
      <h4>רשימת קטגוריות</h4>
      <div className={styles.list}>
        {filteredCategories &&
          filteredCategories.map((i) => (
            <div className={styles.item} key={i._id}>
              {isEdit === i._id ? (
                isEdit ? (
                  <input
                    type="text"
                    className={styles.editField}
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                  />
                ) : (
                  <div
                    className={`${styles.box} ${
                      category && category._id === i._id ? styles.active : ""
                    }`}
                    onClick={() => {
                      setParts(null);
                      setCategory(i);
                    }}
                  >
                    <span>{">"}</span>
                    <p>{i.name}</p>
                  </div>
                )
              ) : (
                <div
                  className={`${styles.box} ${
                    category && category._id === i._id ? styles.active : ""
                  }`}
                  onClick={() => {
                    setParts(null);
                    setCategory(i);
                  }}
                >
                  <span>{">"}</span>
                  <p>{i.name}</p>
                </div>
              )}

              <img
                src="/assets/icons/trash.svg"
                alt="delete"
                className={styles.delete}
                onClick={() => deleteCategory(i._id)}
              />
              {i.isDeleted ? (
                <img
                  src="/assets/icons/eye-close.svg"
                  alt="delete"
                  className={styles.edit}
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "20px",
                  }}
                  onClick={() => updateCategoryVisibility(i._id, false)}
                />
              ) : (
                <img
                  src="/assets/icons/eye.svg"
                  alt="delete"
                  className={styles.edit}
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "20px",
                  }}
                  onClick={() => updateCategoryVisibility(i._id, true)}
                />
              )}

              {isEdit === i._id ? (
                isEdit ? (
                  <img
                    src="/assets/icons/save.png"
                    alt="edit"
                    className={styles.save}
                    onClick={() => updateCategory(i._id)}
                  />
                ) : (
                  <img
                    src="/assets/icons/write.svg"
                    alt="edit"
                    className={styles.edit}
                    onClick={() => {
                      setIsEdit(i._id);
                      setEditValue(i.name);
                    }}
                  />
                )
              ) : (
                <img
                  src="/assets/icons/write.svg"
                  alt="edit"
                  className={styles.edit}
                  onClick={() => {
                    setIsEdit(i._id);
                    setEditValue(i.name);
                  }}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Categories;
