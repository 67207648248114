import styles from "../../styles/_components/search.module.scss";

const Search = ({ placeholder, mb, value, onChange, handleSubmit }) => {
  let marginBottom = mb ? `${mb}0px` : "0px";

  const style = {
    marginBottom,
  };

  return (
    <form className={styles.container} style={style} onSubmit={handleSubmit}>
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <button>חפש</button>
    </form>
  );
};

export default Search;
