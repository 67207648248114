import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AUTH from "../api/services/AUTH";
import Spinner from "../components/ui/Spinner";
import { paths } from "../routes/paths";
import styles from "../styles/_screens/login.module.scss";

const Login = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("authToken");

  // states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  // if token exists, redirect to dash
  useEffect(() => {
    if (token) {
      navigate(paths.main);
    }
  }, [token]);

  // submit form
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setEmailError("Email is required");
    }
    if (!password) {
      setPasswordError("Password is required");
    }

    if (email && password) {
      const payload = {
        email,
        password,
      };

      setLoading(true);
      try {
        const data = await AUTH.login(payload);
        toast.success(data.data.message);
        let token = JSON.stringify(data.data.result.authToken);
        localStorage.setItem("authToken", token);
        navigate(paths.main);
      } catch (error) {
        toast.error(error.response.data.message);
        console.log(error);
      }
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <img src="/assets/icons/logo_2.svg" alt="logo" className={styles.logo} />

      <form autoComplete="off" onSubmit={handleSubmit}>
        <h2>התחברות</h2>

        <div className={styles.field}>
          <label>אימייל</label>
          <input
            type="text"
            name="email"
            placeholder=""
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <small className={styles.error}>{emailError}</small>}
        </div>

        <div className={styles.field}>
          <label>סיסמה</label>
          <input
            name="password"
            placeholder=""
            autoComplete="off"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && (
            <small className={styles.error}>{passwordError}</small>
          )}
        </div>

        <button type="submit">{loading ? <Spinner /> : "התחברות"}</button>

        {/* <small
          className={styles.redirect}
          onClick={() => navigate(paths.register)}
        >
          עדיין אין לך משתמש?
        </small> */}
      </form>
    </div>
  );
};

export default Login;
