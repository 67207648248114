import styles from "../../styles/_components/card.module.scss";

const Card = ({
  children,
  mt,
  mb,
  mr,
  ml,
  maxWidth,
  maxHeight,
  width = "100%",
  height = "100%",
}) => {
  let marginTop = mt ? `${mt}0px` : "0px";
  let marginBottom = mb ? `${mb}0px` : "0px";
  let marginLeft = ml ? `${ml}0px` : "0px";
  let marginRight = mr ? `${mr}0px` : "0px";

  const style = {
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    maxWidth,
    maxHeight,
    width,
    height,
  };

  return (
    <div className={styles.card} style={style}>
      {children}
    </div>
  );
};

export default Card;
