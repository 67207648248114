import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AUTH from "../api/services/AUTH";
import Spinner from "../components/ui/Spinner";
import { paths } from "../routes/paths";
import styles from "../styles/_screens/register.module.scss";

const Register = () => {
  const navigate = useNavigate();
  let formData = new FormData();

  const token = localStorage.getItem("authToken");

  // states
  const [profilePic, setProfilePic] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);

  const [profilePicError, setProfilePicError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [locationError, setLocationError] = useState(null);

  // if token exists, redirect to dash
  useEffect(() => {
    if (token) {
      navigate(paths.main);
    }
  }, [token]);

  // submit form
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!profilePic) {
      setProfilePicError("Profile Pic is required");
    }
    if (!email) {
      setEmailError("Email is required");
    }
    if (!password) {
      setPasswordError("Password is required");
    }
    if (!firstName) {
      setFirstNameError("First Name is required");
    }
    if (!lastName) {
      setLastNameError("Last Name is required");
    }
    if (!location) {
      setLocationError("Address is required");
    }
    if (!phone) {
      setPhoneError("Phone Number is required");
    }

    if (
      profilePic &&
      email &&
      password &&
      firstName &&
      lastName &&
      location &&
      phone
    ) {
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("phone", phone);
      formData.append("countryCode", "+972");
      formData.append("profilePic", profilePic);
      formData.append("location", location);

      setLoading(true);
      try {
        const data = await AUTH.register(formData);
        toast.success(data.data.message);
        navigate(paths.login);
      } catch (error) {
        toast.error(error.response.data.message);
        console.log(error);
      }
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <img src="/assets/icons/logo_2.svg" alt="logo" className={styles.logo} />

      <form onSubmit={handleSubmit} autoComplete="off">
        <h2>הרשמה</h2>

        <div className={styles.avatarField}>
          <div className={`${styles.avatarWrapper}`}>
            <div className={styles.avatarContainer}>
              <img
                src={
                  imagePreview ? imagePreview : "/assets/images/placeholder.jpg"
                }
                alt="placeholder"
              />
              {/* <AiFillCamera size={50} color="#000" /> */}
              <input
                type="file"
                name="profilePic"
                onChange={(e) => {
                  setImagePreview(URL.createObjectURL(e.target.files[0]));
                  setProfilePic(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <label>תמונת פרופיל</label>
          {profilePicError && (
            <small className={styles.error}>{profilePicError}</small>
          )}
        </div>

        <div className={styles.row}>
          <div className={styles.field}>
            <label>שם פרטי</label>
            <input
              type="text"
              name="firstName"
              autoComplete="off"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {firstNameError && (
              <small className={styles.error}>{firstNameError}</small>
            )}
          </div>

          <div className={styles.field}>
            <label>שם משפחה</label>
            <input
              type="text"
              name="lastName"
              autoComplete="off"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {lastNameError && <small className={styles.error}>{lastNameError}</small>}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.field}>
            <label>אימייל</label>
            <input
              type="text"
              name="email"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <small className={styles.error}>{emailError}</small>}
          </div>

          <div className={styles.field}>
            <label>סיסמה</label>
            <input
              name="password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError && (
              <small className={styles.error}>{passwordError}</small>
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.field}>
            <label>טלפון</label>
            <input
              type="number"
              name="phone"
              autoComplete="off"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            {phoneError && <small className={styles.error}>{phoneError}</small>}
          </div>

          <div className={styles.field}>
            <label>כתובת</label>
            <input
              type="text"
              name="location"
              autoComplete="off"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            {locationError && (
              <small className={styles.error}>{locationError}</small>
            )}
          </div>
        </div>

        <button type="submit">{loading ? <Spinner /> : "הרשמה"}</button>
        <small className={styles.redirect} onClick={() => navigate(paths.login)}>כבר רשום למערכת?</small>
      </form>
    </div>
  );
};

export default Register;
