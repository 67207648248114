import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import Form from "../features/supplierCard/components/SupplierCard";
import PartsCategories from "../features/supplierCard/components/PartsCategories";
import Comments from "../features/supplierCard/components/comments/Comments";
import SUPPLIERS from "../api/services/SUPPLIERS";
import { isTokenExpired } from "../utils/isTokenExpired";
import { toast } from "react-toastify";
import { IMAGE_BASE_URL } from "../config";
import Modal from "../components/ui/Modal";
import Spinner from "../components/ui/Spinner";
import { paths } from "../routes/paths";
import styles from "../styles/_screens/supplierCard.module.scss";
import AddManufacturers from "../features/supplierCard/components/AddManufacturers";

const SupplierCard = () => {
  let formdata = new FormData();

  const navigate = useNavigate();

  const { id: supplierID } = useParams();

  const [supplierDetails, setSupplierDetails] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [dltLoading, setDltLoading] = useState(false);

  // form states
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [additionalPhone, setAdditionalPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [profileImagePreview, setProfileImagePreview] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [userName, setUserName] = useState("");
  const [
    blockCustomPartAndVehicleRequests,
    setBlockCustomPartAndVehicleRequests,
  ] = useState(false);

  const [tab, setTab] = useState("CAR");

  // add this line inside your fetchData() function, where you set other properties

  // fetch supplier details by ID
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SUPPLIERS.get_by_id(supplierID);
        setSupplierDetails(res.result);
        setName(res.result.name);
        setPhone(res.result.phone);
        setAdditionalPhone(res.result.additionalPhone);
        setAddress(res.result.address);
        setCity(res.result.city);
        setZipCode(res.result.zipCode);
        setProfileImage(res.result.profileImage);
        setBlockCustomPartAndVehicleRequests(
          res.result.blockCustomPartAndVehicleRequests
        );
        setProfileImagePreview(`${IMAGE_BASE_URL}${res.result.profileImage}`);
        setEmail(res.result.email);
        setStatus(res.result.status);
        // setPassword(res.result.password);
        setUserName(res.result.username);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        isTokenExpired(
          error.response.data.status,
          error.response.data.message,
          navigate
        );
      }
    };

    fetchData();
  }, []);

  // delete supplier
  const deleteSupplier = async (e) => {
    e.preventDefault();

    formdata.append("id", supplierID);
    formdata.append("isDeleted", true);

    setDltLoading(true);
    try {
      const res = await SUPPLIERS.update(formdata);
      toast.success(res.data.message);
      navigate(paths.suppliers);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setDltLoading(false);
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* SUPPLIER CARD */}
          <Form
            data={supplierDetails}
            name={name}
            setName={setName}
            password={password}
            setPassword={setPassword}
            phone={phone}
            setPhone={setPhone}
            additionalPhone={additionalPhone}
            setAdditionalPhone={setAdditionalPhone}
            address={address}
            setAddress={setAddress}
            city={city}
            setCity={setCity}
            zipCode={zipCode}
            setZipCode={setZipCode}
            profileImage={profileImage}
            setProfileImage={setProfileImage}
            profileImagePreview={profileImagePreview}
            setProfileImagePreview={setProfileImagePreview}
            email={email}
            blockCustomPartAndVehicleRequests={
              blockCustomPartAndVehicleRequests
            }
            setBlockCustomPartAndVehicleRequests={
              setBlockCustomPartAndVehicleRequests
            }
            setEmail={setEmail}
            status={status}
            setStatus={setStatus}
            userName={userName}
            setUserName={setUserName}
          />

          {/* PARTS CATEGORIES */}
          <PartsCategories supplierID={supplierID} tab={tab} setTab={setTab} />

          {/* ADD MANUFACTURERS */}
          <AddManufacturers supplierID={supplierID} tab={tab} />

          {/* COMMENTS */}
          <Comments supplierID={supplierID} />

          {/* DELETE */}
          <button className={styles.dlt} onClick={() => setIsModalOpen(true)}>
            מחיקת משתמש
          </button>
        </div>

        {isModalOpen && (
          <Modal setIsOpen={setIsModalOpen}>
            <div className={styles.modalContainer}>
              <h1>האם אתה בטוח?</h1>
              <p>אתה בטוח שאתה רוצה למחוק את המשתמש?</p>

              <div className={styles.btnContainer}>
                <button onClick={() => setIsModalOpen(false)}>לא</button>
                <button onClick={deleteSupplier}>
                  {dltLoading ? <Spinner /> : "כן"}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default SupplierCard;
