import { useNavigate } from "react-router-dom";
import Card from "../../../components/cards/Card";
import styles from "../../../styles/_features/main/components/statsCard.module.scss";

const StatsCard = ({ id, title, value, img, more }) => {
  const navigate = useNavigate();

  return (
    <Card maxWidth="473px" maxHeight="140px" width="100%" height="140px">
      <div className={styles.card}>
        <img src={img} alt={title} />

        <div className={styles.text}>
          <h6>{title}</h6>
          {value && (
            <h3>
              {id === 3
                ? `₪${Number(value.toString().replace("₪", "")).toFixed(0)}`
                : value.toLocaleString()}
            </h3>
          )}
          <p
            onClick={() => {
              if (title === "ספקים פעילים") {
                navigate("/dashboard/suppliers");
              } else {
                navigate("/dashboard/subscribers");
              }
            }}
          >
            {more}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default StatsCard;
