import Header from "./Header";
import styles from "../styles/_layout/layout.module.scss";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main className={styles.main}>
        <div className={styles.wrapper}>{children}</div>
      </main>
    </>
  );
};

export default Layout;
