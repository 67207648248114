import { useEffect, useState } from "react";
import Card from "../../../components/cards/Card";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import SUBSCRIBERS from "../../../api/services/SUBSCRIBERS";
import { toast } from "react-toastify";
import Spinner from "../../../components/ui/Spinner";
import styles from "../../../styles/_features/subscriberCard/components/paymentHistory.module.scss";

const PaymentHistory = ({ subID, lastSub }) => {
  const [value, onChange] = useState(null);

  const [history, setHistory] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  const [price, setPrice] = useState("");

  const [loading, setLoading] = useState(false);

  const [subSuccess, setSubSuccess] = useState(null);
  const [delSuccess, setDelSuccess] = useState(null);

  useEffect(() => {
    setSubSuccess(null);
    setDelSuccess(null);
    const fetchData = async () => {
      try {
        const res = await SUBSCRIBERS.payment_history(subID);
        if (res.result.length > 0) {
          const filtered = res.result.filter(
            (x) => x.subscription.status !== "INACTIVE"
          );
          setHistory(filtered);
        }
      } catch (error) {
        console.log(error);
        if (error.response.data.status === 404) {
          setHistory([]);
        }
      }
    };

    fetchData();
  }, [subSuccess, delSuccess]);

  // add subscription plan to user
  const subscribeUser = async () => {
    if (value && price) {
      const payload = {
        subscriber: subID,
        plan: "633558a1c824549cc7e951f4",
        description: "Payment through cash",
        fromDate: new Date(value[0]).toISOString(),
        toDate: new Date(value[1]).toISOString(),
        price,
      };

      setLoading(true);
      try {
        const res = await SUBSCRIBERS.subscribe(payload);

        toast.success(res.data.message);
        setSubSuccess(true);
        setIsOpen(false);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  };

  // delete subscription
  const cancelSub = async (id) => {
    try {
      const res = await SUBSCRIBERS.cancel_subscription(id);
      console.log(res);
      toast.success(res.data.message);
      setDelSuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Card mb={2}>
      <div className={styles.container}>
        {lastSub && (
          <>
            <h4>חבילה נוכחית למשתמש</h4>
            <table>
              <thead>
                <tr>
                  <th>חבילה נוכחית</th>
                  <th>תאריך</th>
                  <th>סכום</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{lastSub.plan.name}</td>
                  <td>
                    {lastSub.createdAt
                      ? format(new Date(lastSub.createdAt), "dd.MM.yy")
                      : "-"}
                  </td>

                  <td>₪{lastSub.price}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        <h3>היסטוריית תשלומים</h3>

        {history && history.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>תאריך</th>
                <th>סכום</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {history.map((i) => (
                <tr>
                  <td>
                    {i.subscription.startsAt
                      ? format(new Date(i.subscription.startsAt), "dd.MM.yy")
                      : "-"}
                    -{" "}
                    {i.subscription.endsAt
                      ? format(new Date(i.subscription.endsAt), "dd.MM.yy")
                      : "-"}
                  </td>
                  <td>₪{i.price}</td>
                  {i.subscription.plan.type === "CUSTOM" &&
                    i.subscription.status === "ACTIVE" && (
                      <td>
                        <img
                          src="/assets/icons/trash.svg"
                          alt="delete"
                          onClick={() => cancelSub(i.subscriber)}
                        />
                      </td>
                    )}
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <button className={styles.btn} onClick={() => setIsOpen(true)}>
          פתיחת מנוי לפי תאריך
        </button>

        {isOpen && (
          <div className={styles.subscribe}>
            <Calendar
              onChange={onChange}
              value={value}
              next2Label={false}
              prev2Label={false}
              className="calendar"
              formatShortWeekday={(locale, date) => format(date, "EEEEE")}
              selectRange={true}
            />

            <input
              type="number"
              value={price}
              placeholder="סכום תשלום"
              onChange={(e) => setPrice(e.target.value)}
            />

            <button className={styles.btn} onClick={subscribeUser}>
              {loading ? <Spinner /> : "פתח חבילה ידנית"}
            </button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default PaymentHistory;
