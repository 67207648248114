import { useNavigate } from "react-router-dom";
import Label from "../../../components/ui/Label";
import Switch from "react-switch";
import SUPPLIERS from "../../../api/services/SUPPLIERS";

const TableRowSupplier = ({ row, setIsUpdated, isUpdated, setIsSupDltModalOpen }) => {
  const formdata = new FormData();
  const navigate = useNavigate();

  const { _id, name, phone, address, email, city, status } = row;

  const handleChange = async () => {
    setIsUpdated(!isUpdated);

    formdata.append("id", _id);
    if (status === "ACTIVE") {
      formdata.append("status", "INACTIVE");
    } else {
      formdata.append("status", "ACTIVE");
    }

    try {
      const res = await SUPPLIERS.update(formdata);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <tr>
      {/* <td style={{ width: "50px" }}>{_id}</td> */}
      <td style={{ width: "100px" }}>{name}</td>
      <td style={{ width: "100px" }}>{phone}</td>
      <td style={{ width: "150px" }}>{address}</td>
      <td style={{ width: "100px" }}>{email}</td>
      <td style={{ width: "100px" }}>{city ? city : "-"}</td>
      <td style={{ width: "50px" }}>
        <Label text={status} />
      </td>
      <td style={{ width: "150px", textAlign: "center", paddingLeft: "100px" }}>
        <Switch
          onChange={handleChange}
          checked={status === "ACTIVE"}
          onColor="#0AB39C"
          checkedIcon={false}
          uncheckedIcon={false}
        />
      </td>
      <td style={{ width: "80px" }}>
        <img
          src="/assets/icons/share.svg"
          alt="share"
          onClick={() => window.open(`/dashboard/supplier-card/${_id}`, "_blank")}
          />
        <img src="/assets/icons/trash_2.svg" alt="delete" className="tableDeleteIcon" onClick={() => setIsSupDltModalOpen(_id)} />
      </td>
    </tr>
  );
};

export default TableRowSupplier;
