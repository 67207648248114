import Label from "../../../components/ui/Label";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import SUBSCRIBERS from "../../../api/services/SUBSCRIBERS";
import { format } from "date-fns";

const TableRowSubscriber = ({
  row,
  setIsUpdated,
  isUpdated,
  setIsSubDltModalOpen,
}) => {
  const navigate = useNavigate();

  const formdata = new FormData();
  function translateSubscriberType(type) {
    switch (type) {
        case 'GARAGE': return 'מוסך';
        case 'PRIVATE': return 'פרטי';
        case 'LEASING_COMPANY': return 'ליסינג';
        default: return type; // return the original type if it's none of the above
    }
}
  const { _id, name, phone, totalSearches, email, status, lastSubscription,createdAt,subscriberType } =
    row;
  // status change
  const handleChange = async () => {
    setIsUpdated(!isUpdated);

    formdata.append("id", _id);
    if (status === "ACTIVE") {
      formdata.append("status", "INACTIVE");
    } else {
      formdata.append("status", "ACTIVE");
    }

    try {
      const res = await SUBSCRIBERS.update(formdata);
      console.log(res.result.length, "+++++++");
    } catch (error) {
      console.log(error);
    }
  };


  const renderDaysLeft = () => {
    if (lastSubscription) {
      const endsAt = new Date(lastSubscription.endsAt);
      const today = new Date();
      const daysLeft = Math.floor((endsAt - today) / (1000 * 60 * 60 * 24));
      

      if (daysLeft > 10) {
        return (
          <span style={{ backgroundColor: '#eef9ea' }}>
           {format(new Date(lastSubscription.endsAt), "dd/MM/yy")} <br/> נותרו: <span style={{fontWeight:'600', fontSize:'18px'}}> {daysLeft} </span>ימים
          </span>
        );
      } else if (daysLeft > 3) {
        return (
          <span style={{ backgroundColor: '#ffcc33' }}>
          {format(new Date(lastSubscription.endsAt), "dd/MM/yy")} <br/> נותרו: <span style={{fontWeight:'600', fontSize:'18px'}}> {daysLeft} </span>ימים
          </span>
        );
      } else if (daysLeft >= 0) {
        return (
          <span style={{ backgroundColor: '#f99f9f' }}>
            {format(new Date(lastSubscription.endsAt), "dd/MM/yy")} <br/> נותרו: <span style={{fontWeight:'600', fontSize:'18px'}}> {daysLeft}</span> ימים
          </span>
        );
      }
    }

    return 'לא קיים';
  };
  return (
    <tr>
      {/* <td style={{ width: "50px" }}>{_id}</td> */}
      <td style={{ width: "100px" }}>{name}</td>
      <td style={{ width: "100px" }}>{phone}</td>
      <td style={{ width: "150px", textAlign: "center", paddingLeft: "100px" }}>
        {totalSearches}
      </td>
      <td style={{ width: "100px" }}>{email}</td>
      <td style={{ width: "50px" }}>
        <Label text={status} />
      </td>
      <td style={{ width: "100px" }}>
        {lastSubscription ? lastSubscription.plan.name : "-"}
      </td>
      <td style={{ width: "100px" }}>
        {translateSubscriberType(subscriberType)}
      </td>
      <td style={{ width: "100px" }}>
      {format(new Date(createdAt), "dd.MM.yy")}
      </td>
      <td style={{ width: "100px" }}>{renderDaysLeft()}</td>
      <td style={{ width: "100px", textAlign: "center", paddingLeft: "100px" }}>
        <Switch
          onChange={handleChange}
          checked={status === "ACTIVE"}
          onColor="#0AB39C"
          checkedIcon={false}
          uncheckedIcon={false}
        />
      </td>
      <td style={{ width: "80px" }}>
        <img
          src="/assets/icons/share.svg"
          alt="share"
          onClick={() =>
            window.open(`/dashboard/subscriber-card/${_id}`, "_blank")
          }
        />
        <img
          src="/assets/icons/trash_2.svg"
          alt="delete"
          className="tableDeleteIcon"
          onClick={() => setIsSubDltModalOpen(_id)}
        />
      </td>
    </tr>
  );
};

export default TableRowSubscriber;
