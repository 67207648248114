import { useState, useEffect } from "react";
import SUPPLIERS from "../../../api/services/SUPPLIERS";
import styles from "../../../styles/_features/allRequests/selectSupplier.module.scss";

const SelectSupplier = ({ supplier, setSupplier }) => {
  const [suppliers, setSuppliers] = useState([]);
  const [val, setVal] = useState("");

  // GET SUPPLIERS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SUPPLIERS.get("", "", val);
        setSuppliers(res.result.records);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [val]);

  return (
    <div className={styles.container}>
      <input
        type="text"
        name="val"
        value={supplier ? supplier.name : val}
        placeholder="חיפוש ספק"
        onChange={(e) => setVal(e.target.value)}
        style={{
          borderBottomLeftRadius: val.length > 0 ? "0px" : "5px",
          borderBottomRightRadius: val.length > 0 ? "0px" : "5px",
        }}
        disabled={supplier}
      />
      {supplier && (
        <span onClick={() => setSupplier(null)} className={styles.remove}>
          X
        </span>
      )}
      {val.length > 0 && (
        <div className={styles.dropdown}>
          <ul>
            {suppliers?.map((i) => (
              <li
                key={i._id}
                onClick={() => {
                  setSupplier(i);
                  setVal("");
                }}
              >
                {i.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectSupplier;
