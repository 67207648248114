import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CATEGORIES from "../api/services/CATEGORIES";
import Categories from "../containers/partsList/Categories";
import Parts from "../containers/partsList/Parts";
import Tabs from "../features/partsList/components/Tabs";
import Layout from "../layouts/Layout";
import styles from "../styles/_screens/partsList.module.scss";
import { isTokenExpired } from "../utils/isTokenExpired";

const PartsList = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("CAR");

  const [categories, setCategories] = useState();
  const [filteredCategories, setFilteredCategories] = useState();

  const [category, setCategory] = useState(null);

  const [createCategorySuccess, setCreateCategorySuccess] = useState(null);
  const [deleteCategorySuccess, setDeleteCategorySuccess] = useState(null);
  const [updateCategorySuccess, setUpdateCategorySuccess] = useState(null);

  const [parts, setParts] = useState();

  // FETCH CATEGORIES
  useEffect(() => {
    setCreateCategorySuccess(null);
    setDeleteCategorySuccess(null);
    setUpdateCategorySuccess(null);
    const fetchCategories = async () => {
      try {
        const res = await CATEGORIES.get();
        setCategories(res.result);
      } catch (error) {
        console.log(error);
        isTokenExpired(error.response.data.status, error.response.data.message, navigate);
      }
    };

    fetchCategories();
  }, [createCategorySuccess, deleteCategorySuccess, updateCategorySuccess]);

  // FILTER CATEGORIES BY VEHICLE
  useEffect(() => {
    if (categories) {
      const filtered = categories.filter((i) => i.vehicle === tab);
      setFilteredCategories(filtered);
    }
  }, [categories, tab]);

  return (
    <Layout>
      <div className={styles.container}>
        {/* TABS */}
        <Tabs tab={tab} setTab={setTab} setCategory={setCategory} />

        <h2>רשימת חלקים</h2>

        <div className={styles.subContainer}>
          {/* CATEGORIES CONTAINER */}
          <Categories
            filteredCategories={filteredCategories}
            setCategory={setCategory}
            category={category}
            tab={tab}
            setCreateCategorySuccess={setCreateCategorySuccess}
            setDeleteCategorySuccess={setDeleteCategorySuccess}
            setUpdateCategorySuccess={setUpdateCategorySuccess}
            setParts={setParts}
          />

          <div className={styles.separator} />

          {/* PARTS CONTAINER */}
          {category && (
            <Parts category={category} parts={parts} setParts={setParts} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PartsList;
