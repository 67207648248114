import React from "react";
import axios from "axios";

const primaryResources = [
  {
    rid: "053cea08-09bc-40ec-8f7a-156f0677aff3",
    filters: (text) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
  },
  {
    rid: "0866573c-40cd-4ca8-91d2-9dd2d7a492e5",
    filters: (text) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
  },
  {
    rid: "03adc637-b6fe-402b-9937-7c3d3afc9140",
    filters: (text) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
  },
  {
    rid: "f6efe89a-fb3d-43a4-bb61-9bf12a9b9099",
    filters: (text) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
  },
  {
    rid: "6f6acd03-f351-4a8f-8ecf-df792f4f573a",
    filters: (text) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
  },
  {
    rid: "142afde2-6228-49f9-8a29-9b6c3a0cbe40",
    filters: (text) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
  },
  {
    rid: "83bfb278-7be1-4dab-ae2d-40125a923da1",
    filters: (text) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
  },
  {
    rid: "36bf1404-0be4-49d2-82dc-2f1ead4a8b93",
    filters: (text) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
  },
  {
    rid: "bb2355dc-9ec7-4f06-9c3f-3344672171da",
    filters: (text) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
  },
  {
    rid: "bf9df4e2-d90d-4c0a-a400-19e15af8e95f",
    filters: (text) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "BIKE",
  },
  {
    rid: "cd3acc5c-03c3-4c89-9c54-d40f93c0d790",
    filters: (text) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "TRUCK",
  },
];

const secondaryResources = [
  {
    rid: "142afde2-6228-49f9-8a29-9b6c3a0cbe40",
    filters: ({ kinuy_mishari, degem_nm, ramat_gimur }) => ({
      kinuy_mishari: kinuy_mishari,
      degem_nm: degem_nm,
      ramat_gimur: ramat_gimur,
    }),
    filterPayloadKeys: ["kinuy_mishari", "degem_nm", "ramat_gimur"],
    hasSomeRequiredFilterPayloadKeys: false,
    filterResultKeys: [],
    tags: ["CAR"],
  },

  {
    rid: "142afde2-6228-49f9-8a29-9b6c3a0cbe40",
    filters: ({ degem_cd, degem_nm, shnat_yitzur }) => ({
      degem_cd,
      degem_nm,
      shnat_yitzur,
    }),
    filterPayloadKeys: ["degem_cd", "degem_nm", "shnat_yitzur"],
    hasSomeRequiredFilterPayloadKeys: true,
    filterResultKeys: ["automatic_ind"],
    tags: ["CAR"],
  },
];

export const fieldNames = {
  mispar_rechev: 'מספר רכב',
  tozeret_cd: 'קוד יצרן',
  sug_degem: 'סוג דגם',
  tozeret_nm: 'יצרן',
  degem_cd: 'קוד דגם',
  degem_nm: 'שם דגם',
  ramat_gimur: 'רמת גימור',
  ramat_eivzur_betihuty: 'רמת אבזור בטיחותי',
  kvutzat_zihum: 'קבוצת זיהום',
  shnat_yitzur: 'שנת יצור',
  degem_manoa: 'דגם מנוע',
  mivchan_acharon_dt: 'טסט אחרון',
  mispar_dlatot: 'מספר דלתות',
  tokef_dt: 'תוקף רישיון רכב',
  baalut: 'בעלות',
  misgeret: 'מספר שילדה',
  tzeva_cd: 'קוד צבע',
  tzeva_rechev: 'צבע רכב',
  zmig_kidmi: 'צמיג קדמי',
  zmig_ahori: 'צמיג אחורי',
  sug_delek_nm: 'סוג דלק',
  horaat_rishum: 'הוראת רישום',
  moed_aliya_lakvish: 'מועד עלייה לכביש',
  kinuy_mishari: 'דגם',
  nefah_manoa: 'נפח מנוע',
  nefach_manoa: 'נפח מנוע',
  hanaa_nm: 'הנעה',
  delek_nm: 'דלק',
  mazgan_ind: 'מזגן',
  technologiat_hanaa_nm: 'טכנולוגית הנעה',
  kosher_grira_bli_blamim: 'כושר גלילה בלי בלמים',
  kvuzat_agra_cd: 'קבוצת אגרה',
  merkav: 'מרכב',
  // automatic_ind: 'תיבת הילוכים',
  mispar_moshavim: 'מספר מושבים',
  mishkal_kolel: 'משקל',
  koah_sus: 'כוס סוס',
  abs_ind: 'ABS',
  kosher_grira_im_blamim: 'כושר גרירה עם בלמים',
}

export const engineType = {
  ['בנזין']: 'GASOLINE',
  ['טורבו בנזין']: 'TURBO GASOLINE',
  ['דיזל']: 'DIESEL',
  ['טורבו דיזל']: 'TURBO DIESEL',
  ['היברידי']: 'HYBRID',
  ['חשמל']: 'ELECTRICAL POWER',
  ['חשמל/בנזין']: 'ELECTRICITY/GASOLINE',
  ['גפ"מ']: 'GAPAM',
}

export const gearType = {
  ['0']: 'MANUAL',
  ['1']: 'AUTOMATIC',
  ['2']: 'TIPTONIC',
  ['3']: 'ROBOTIC',
}

const searchLicense = async ({ rid, type = "GENERAL", filters }) => {
  let baseUrl = "";
  if (type === "TRUCK") {
    baseUrl = `https://data.gov.il/api/3/action/datastore_search?resource_id=${rid}&filters=${JSON.stringify(
      filters
    )}`;
  } else {
    baseUrl = `https://data.gov.il/api/action/datastore_search?resource_id=${rid}&filters=${JSON.stringify(
      filters
    )}`;
  }

  // api call
  try {
    const res = await axios.get(baseUrl);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const useLicenseSearch = () => {
  const [loading, setLoading] = React.useState(false)
  const licenseExtraDataRef = React.useRef({});

 

  const searchLicenseByResourceId = React.useCallback(
    async (resourcesArray, { rid, filters, secondaryApiTag }, text, index) => {
      let res = await searchLicense({
        rid,
        filters,
      });
      if (
        (res &&
          res.data &&
          res.data.result &&
          (!res.data.result.records.length ||
            res.data.result.records.length > 1)) ||
        res.error
      ) {
        if (index === resourcesArray.length - 1) {
          return {
            licenseData: null,
            secondaryApiTag: null,
          };
        } else {
          return await searchLicenseByResourceId(
            resourcesArray,
            {
              rid: resourcesArray[index + 1].rid,
              filters: resourcesArray[index + 1].filters(text),
              secondaryApiTag: resourcesArray[index + 1].secondaryApiTag,
            },
            text,
            index + 1
          );
        }
      } else {
        // console.log(
        //   "Found in resourceId:",
        //   rid,
        //   " Belongs to:",
        //   secondaryApiTag
        // );
        return {
          licenseData: res?.data?.result?.records?.[0] || {},
          secondaryApiTag,
        };
      }
    },
    [searchLicense]
  );

  const searchLicenseExtraDataByResourceId = React.useCallback(
    async (resourcesArray, { rid, filters }, index, licenseData) => {
      if (
        resourcesArray[index].hasSomeRequiredFilterPayloadKeys &&
        resourcesArray[index].filterPayloadKeys.some((i) => !licenseData[i])
      ) {
        if (index === resourcesArray.length - 1) {
          return licenseExtraDataRef.current;
        } else {
          const _filtersPayload = resourcesArray[
            index + 1
          ].filterPayloadKeys.reduce((a, i) => {
            a[i] = (licenseData[i] || "").toString();
            return a;
          }, {});
          return await searchLicenseExtraDataByResourceId(
            resourcesArray,
            {
              rid: resourcesArray[index + 1].rid,
              filters: resourcesArray[index + 1].filters(_filtersPayload),
            },
            index + 1,
            licenseData
          );
        }
      }
      let res = await searchLicense({
        rid,
        filters,
      });
      if (
        res &&
        res.data &&
        res.data.result &&
        res.data.result.records.length
      ) {
        if (resourcesArray[index].filterResultKeys.length) {
          const filteredResult = resourcesArray[index].filterResultKeys.reduce(
            (a, i) => {
              a[i] = res.data.result.records?.[0]?.[i] || "";
              return a;
            },
            {}
          );
          licenseExtraDataRef.current = {
            ...licenseExtraDataRef.current,
            ...filteredResult,
          };
        } else {
          licenseExtraDataRef.current = {
            ...licenseExtraDataRef.current,
            ...res.data.result.records[0],
          };
        }
      }
      if (index === resourcesArray.length - 1) {
        return licenseExtraDataRef.current;
      } else {
        const _filtersPayload = resourcesArray[
          index + 1
        ].filterPayloadKeys.reduce((a, i) => {
          a[i] = (licenseData[i] || "").toString();
          return a;
        }, {});
        return await searchLicenseExtraDataByResourceId(
          resourcesArray,
          {
            rid: resourcesArray[index + 1].rid,
            filters: resourcesArray[index + 1].filters(_filtersPayload),
          },
          index + 1,
          licenseData
        );
      }
    },
    [searchLicense]
  );

  const searchForLicense = React.useCallback(
    async (text) => {
      try {
      // if (!text.trim().length && fromSearchForm) {
      //   setLicenseData({});
      //   setFields?.(initalFields);
      //   setDisableAllFields?.(false);
      //   return;
      // }
      // if (text.trim().length < 5 && fromSearchForm) {
      //   return;
      // }
      licenseExtraDataRef.current = {};
      setLoading(true);
      const response = await searchLicenseByResourceId(
        primaryResources,
        {
          rid: primaryResources[0].rid,
          filters: primaryResources[0].filters(text),
          secondaryApiTag: primaryResources[0].secondaryApiTag,
        },
        text,
        0
      );
      if (response.licenseData) {
        const filteredSecondaryApis = secondaryResources.filter((i) =>
          i.tags.includes(response.secondaryApiTag)
        );
        if (filteredSecondaryApis.length) {
          const filtersPayload =
            filteredSecondaryApis[0].filterPayloadKeys.reduce((a, i) => {
              a[i] = (response.licenseData[i] || "").toString();
              return a;
            }, {});
          await searchLicenseExtraDataByResourceId(
            filteredSecondaryApis,
            {
              rid: filteredSecondaryApis[0].rid,
              filters: filteredSecondaryApis[0].filters(filtersPayload),
            },
            0,
            response.licenseData
          );
        }
        const eType = response.licenseData?.sug_delek_nm || ''
        const gType =  response.secondaryApiTag === 'CAR' && response.licenseData.degem_cd
        ? String(licenseExtraDataRef.current?.automatic_ind || '')
        : ''
        return {
          success: true,
          message: "",
          data: {
            ...response.licenseData,
            ...licenseExtraDataRef.current,
            ...(response.secondaryApiTag !== "CAR" && {
              nefah_manoa: "",
              automatic_ind: "",
            }),
          },
          localData: {
            manufacturer: response.licenseData?.tozeret_nm || 'לא צוין',
            model: response.secondaryApiTag === 'CAR'
            ? response.licenseData?.kinuy_mishari ||
                response.licenseData?.degem_nm ||
                'לא צוין'
            : response.licenseData?.degem_nm || '',
            subModel:  response.licenseData?.ramat_gimur ||
            response.licenseData?.degem_nm ||
            '',
            licenseNumber: text.trim(),
            yop: String(response.licenseData?.shnat_yitzur || ''),
            hp: response.secondaryApiTag === 'CAR'
            ? String(
                licenseExtraDataRef.current?.nefah_manoa ||
                  response.licenseData.nefach_manoa ||
                  '',
              )
            : response.licenseData?.nefach_manoa || '',
            engineType: engineType[eType] ? engineType[eType] : '',
            gearType: gearType[gType] ? gearType[gType] : '',
            vehicle: response.secondaryApiTag,
          }
        };
      } else {
        return {
          success: false,
          data: {},
          localdata: {},
          message: "מספר רישיון לא נמצא!",
        };
      }
    } catch(e) {

    } finally {
      setLoading(false)
    }
    },
    [searchLicenseByResourceId, setLoading, searchLicenseExtraDataByResourceId]
  );
  return {
    searchForLicense,
    loading
  };
};
