import { useState } from "react";
import styles from "../../../styles/_features/supplierCard/components/parts.module.scss";

const Parts = ({ parts, selectedCategory, setSelectedCategory, category, selectAll, setSelectAll }) => {

  // handle part click
  const handleClick = (id) => {
    // if part already assigned then remove it
    if (selectedCategory.parts.some((part) => part.id === id)) {
      let partsCopy = selectedCategory.parts;

      partsCopy = partsCopy.filter((i) => i.id !== id);

      const obj = {
        cat: category,
        parts: partsCopy,
      };

      setSelectedCategory(obj);

      // if part doesn't exist in the array then add it
    } else {
      let partsCopy = selectedCategory.parts;

      partsCopy = [...partsCopy, { id: id }];

      const obj = {
        cat: category,
        parts: partsCopy,
      };

      setSelectedCategory(obj);
    }
  };

  // handle select all click
  const handleSelectAll = () => {
    if (selectAll) {
      const obj = {
        cat: category,
        parts: [],
      };

      setSelectedCategory(obj);
      setSelectAll(false);
    } else {
      setSelectedCategory(parts.map((i) => i._id));
      const obj = {
        cat: category,
        parts: parts.map((i) => {
          return {
            id: i._id,
          };
        }),
      };

      setSelectedCategory(obj);
      setSelectAll(true);
    }
  };

  return (
    <div className={styles.container}>
      <h4>בחירת חלק</h4>

      <div className={styles.selectAll}>
        <input
          type="checkbox"
          name="selectAll"
          onChange={handleSelectAll}
          checked={selectAll}
        />
        <p>סמן את כל החלקים</p>
      </div>

      <ul className={styles.parts}>
        {parts.map((i) => (
          <li
            className={styles.part}
            key={i._id}
            onClick={() => handleClick(i._id)}
          >
            <input
              type="checkbox"
              checked={selectedCategory.parts.some((part) => part.id === i._id)}
            />
            <p>{i.name}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Parts;
