import { useState } from "react";
import { toast } from "react-toastify";
import SUPPLIERS from "../../../api/services/SUPPLIERS";
import Card from "../../../components/cards/Card";
import Loader from "../../../components/ui/Loader";
import Spinner from "../../../components/ui/Spinner";
import styles from "../../../styles/_features/supplierCard/components/supplierCard.module.scss";

const SupplierCard = ({
  data,
  name,
  setName,
  password,
  setPassword,
  phone,
  setPhone,
  additionalPhone,
  setAdditionalPhone,
  address,
  setAddress,
  city,
  setCity,
  zipCode,
  setZipCode,
  profileImage,
  setProfileImage,
  profileImagePreview,
  setProfileImagePreview,
  email,
  setEmail,
  status,
  setStatus,
  userName,
  setUserName,
  blockCustomPartAndVehicleRequests,
  setBlockCustomPartAndVehicleRequests,
}) => {
  const formdata = new FormData();
  const [loading, setLoading] = useState(false);
  console.log(
    "Initial blockCustomPartAndVehicleRequests:",
    blockCustomPartAndVehicleRequests
  );

  if (!data) {
    return (
      <Card mb={3}>
        <div className={styles.container}>
          <Loader />
        </div>
      </Card>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    formdata.append("id", data._id);
    name && formdata.append("name", name);
    password && formdata.append("password", password);
    phone && formdata.append("phone", phone);
    additionalPhone && formdata.append("additionalPhone", additionalPhone);
    email && formdata.append("email", email);
    address && formdata.append("address", address);
    status && formdata.append("status", status);
    zipCode && formdata.append("zipCode", zipCode);
    city && formdata.append("city", city);
    profileImage && formdata.append("profileImage", profileImage);
    userName && formdata.append("username", userName);
    formdata.append(
      "blockCustomPartAndVehicleRequests",
      blockCustomPartAndVehicleRequests
    );

    setLoading(true);
    try {
      const res = await SUPPLIERS.update(formdata);
      toast.success("ספק עודכן בהצלחה");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <Card mb={3}>
      <div className={styles.container}>
        <h3>כרטיס ספק</h3>

        <form onSubmit={handleSubmit}>
          <div className={styles.field}>
            <label>שלח רק חלקים/יצרנים שסומנו</label>
            <input
              type="checkbox"
              checked={blockCustomPartAndVehicleRequests}
              onChange={(e) =>
                setBlockCustomPartAndVehicleRequests(e.target.checked)
              }
            />
          </div>
          <div className={styles.field}>
            <label>שם הספק / עסק</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={styles.field}>
            <label>סיסמה</label>
            <input
              type="text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className={styles.field}>
            <label>טלפון</label>
            <input
              type="number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className={styles.field}>
            <label>טלפון נוסף (לא חובה)</label>
            <input
              type="number"
              value={additionalPhone}
              onChange={(e) => setAdditionalPhone(e.target.value)}
            />
          </div>
          <div className={styles.field}>
            <label>כתובת</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className={styles.field}>
            <label>מייל</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.field}>
            <label>שם משתמש</label>
            <input
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div className={styles.field}>
            <label>עיר</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          {/* <div className={styles.field}>
            <label>מיקוד</label>
            <input
              type="text"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </div> */}
          <div className={styles.field}>
            <label>סטטוס</label>
            <select
              name="סטטוס"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="סטטוס" disabled>
                סטטוס
              </option>
              <option value="ACTIVE">פעיל</option>
              <option value="INACTIVE">לא פעיל</option>
            </select>
          </div>
          <div className={styles.imgUpload}>
            <input
              type="file"
              onChange={(e) => {
                setProfileImage(e.target.files[0]);
                setProfileImagePreview(URL.createObjectURL(e.target.files[0]));
              }}
            />
            <p>תמונה</p>
          </div>
          <div className={styles.imgPreview}>
            <img src={profileImagePreview} alt="supplier-pic" />
          </div>{" "}
          <button style={{ margin: "0 auto" }} type="submit">
            {loading ? <Spinner /> : "שמור"}
          </button>
        </form>
      </div>
    </Card>
  );
};

export default SupplierCard;
