import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import Form from "../features/subscriberCard/components/SubscriberCard";
import Comments from "../features/subscriberCard/components/comments/Comments";
import PaymentHistory from "../features/subscriberCard/components/PaymentHistory";
import SUBSCRIBERS from "../api/services/SUBSCRIBERS";
import { toast } from "react-toastify";
import { isTokenExpired } from "../utils/isTokenExpired";
import Modal from "../components/ui/Modal";
import styles from "../styles/_screens/subscriberCard.module.scss";
import Spinner from "../components/ui/Spinner";
import { paths } from "../routes/paths";

const SubscriberCard = () => {
  let formdata = new FormData();
  const navigate = useNavigate();

  const { id: subID } = useParams();

  const [subscriberDetails, setSubscriberDetails] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [dltLoading, setDltLoading] = useState(false);

  // form states
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [status, setStatus] = useState("");
  const [subType, setSubType] = useState("");
  const [lastSub, setLastSub] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState("");
  const [password, setPassword] = useState("");
  const [emailOTP, setemailOTP] = useState("");
  const [createdAt, setcreatedAt] = useState("");
  const [userName, setUserName] = useState("");

  // fetch subscriber details by ID
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SUBSCRIBERS.get_by_id(subID);
        setSubscriberDetails(res.result);
        setName(res.result.name);
        setPhone(res.result.phone);
        setAddress(res.result.address);
        setEmail(res.result.email);
        setCity(res.result.city);
        setZipCode(res.result.zipCode);
        setStatus(res.result.status);
        setSubType(res.result.subscriberType);
        setLastSub(res.result.lastSubscription);
        setIsEmailVerified(res.result.isEmailVerified);
        // setPassword(res.result.password);
        setemailOTP(res.result.emailOTP);
        setcreatedAt(res.result.createdAt);
        setUserName(res.result.username);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        isTokenExpired(error.response.data.status, navigate);
      }
    };

    fetchData();
  }, []);

  // delete subscriber
  const deleteSubscriber = async (e) => {
    e.preventDefault();

    formdata.append("id", subID);
    formdata.append("isDeleted", true);

    setDltLoading(true);
    try {
      const res = await SUBSCRIBERS.update(formdata);
      toast.success(res.data.message);
      navigate(paths.subscribers);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setDltLoading(false);
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* FORM */}
          <Form
            data={subscriberDetails}
            name={name}
            setName={setName}
            phone={phone}
            setPhone={setPhone}
            address={address}
            setAddress={setAddress}
            email={email}
            setEmail={setEmail}
            city={city}
            setCity={setCity}
            zipCode={zipCode}
            setZipCode={setZipCode}
            status={status}
            setStatus={setStatus}
            subType={subType}
            setSubType={setSubType}
            isEmailVerified={isEmailVerified}
            password={password}
            setPassword={setPassword}
            emailOTP={emailOTP}
            createdAt={createdAt}
            userName={userName}
            setUserName={setUserName}
          />

          {/* PAYMENT HISTORY */}
          <PaymentHistory subID={subID} lastSub={lastSub} />

          {/* COMMENTS */}
          <Comments subID={subID} />

          {/* DELETE */}
          <button className={styles.dlt} onClick={() => setIsModalOpen(true)}>
            מחיקת משתמש
          </button>
        </div>

        {isModalOpen && (
          <Modal setIsOpen={setIsModalOpen}>
            <div className={styles.modalContainer}>
              <h1>האם אתה בטוח?</h1>
              <p>אתה בטוח שאתה רוצה למחוק את המשתמש?</p>

              <div className={styles.btnContainer}>
                <button onClick={() => setIsModalOpen(false)}>לא</button>
                <button onClick={deleteSubscriber}>
                  {dltLoading ? <Spinner /> : "כן"}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default SubscriberCard;
