import { useEffect } from "react";
import { useState } from "react";
import MANUFACTURERS from "../api/services/MANUFACTURERS";
import Loader from "../components/ui/Loader";
import Manufacturers from "../containers/_manufacturer/Manufacturers";
import Models from "../containers/_manufacturer/Models";
import Tabs from "../features/partsList/components/Tabs";
import Layout from "../layouts/Layout";
import styles from "../styles/_screens/manufacturer.module.scss";

const Manufacturer = () => {
  // vehicles
  const [tab, setTab] = useState("CAR");

  // manufacturers
  const [manufacturers, setManufacturers] = useState(null);

  // selected manufacturer
  const [manufacturer, setManufacturer] = useState(null);

  // models
  const [models, setModels] = useState([]);

  // selected model
  const [model, setModel] = useState(null);

  // submit state
  const [createManu, setCreateManu] = useState(false);
  const [updateManu, setUpdateManu] = useState(false);
  const [deleteManu, setDeleteManu] = useState(false);
  const [updateModel, setUpdateModel] = useState(false);

  // fetch manufacturers and models
  useEffect(() => {
    setCreateManu(null);
    setUpdateManu(null);
    setDeleteManu(null);
    setUpdateModel(null);
    const fetchData = async () => {
      try {
        const res = await MANUFACTURERS.get(tab);
        if (res.result.manufacturers.length > 0) {
          setManufacturers(res.result.manufacturers);
          // console.log(res.result.manufacturers);
        } else {
          setManufacturers([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [tab, createManu, updateManu, deleteManu, updateModel]);

  // loader while manufacturers are loading
  if (!manufacturers) {
    return (
      <Layout>
        <div className={styles.container}>
          <Tabs tab={tab} setTab={setTab} setCategory={() => null} />
          <Loader />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        <Tabs tab={tab} setTab={setTab} setCategory={() => null} />

        <h2>יצרנים ודגמים</h2>

        <div className={styles.subContainer}>
          <Manufacturers
            manufacturer={manufacturer}
            manufacturers={manufacturers}
            setManufacturer={setManufacturer}
            setModels={setModels}
            tab={tab}
            setCreateManu={setCreateManu}
            setUpdateManu={setUpdateManu}
            setDeleteManu={setDeleteManu}
          />

          <div className={styles.separator} />

          {manufacturer && (
            <Models
              models={models}
              setModels={setModels}
              manufacturer={manufacturer}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Manufacturer;
