import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SUBSCRIBERS from "../api/services/SUBSCRIBERS";
import SUPPLIERS from "../api/services/SUPPLIERS";
import Table from "../components/table/Table";
import TableHead from "../components/table/TableHead";
import Modal from "../components/ui/Modal";
import Stats from "../containers/_main/Stats";
import TableRowSubscriber from "../features/main/components/TableRowSubscriber";
import TableRowSupplier from "../features/main/components/TableRowSupplier";
import Layout from "../layouts/Layout";
import { paths } from "../routes/paths";
import { isTokenExpired } from "../utils/isTokenExpired";
import Loader from "../components/ui/Loader";
import STATS from "../api/services/STATS";
import styles from "../styles/_screens/main.module.scss";
import Spinner from "../components/ui/Spinner";

const Main = () => {
  let formdata = new FormData();

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const token = localStorage.getItem("authToken");

  // if token does not exists, redirect to login
  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [token]);

  const SUPPLIER_TABLE_HEAD = [
    // "#",
    "שם הספק",
    "טלפון",
    "כתובת",
    "מייל",
    "סטטוס",
    "",
  ];

  const SUBSCRIBERS_TABLE_HEAD = [
    // "#",
    "שם מנוי",
    "טלפון",
    "חיפושים",
    "מייל",
    "סוג מנוי",
    "סוג לקוח",
    "נוצר בתאריך",
    "סיום חבילה",
    "סטטוס",
  ];

  const [suppliers, setSuppliers] = useState();

  const [subscribers, setSubscribers] = useState();

  const [createSuccess, setCreateSuccess] = useState(null);

  const [deleteSuccess, setDeleteSuccess] = useState(null);

  const [stats, setStats] = useState(null);

  const [isSupDltModalOpen, setIsSupDltModalOpen] = useState(false);

  const [isSubDltModalOpen, setIsSubDltModalOpen] = useState(false);

  const [dltSupLoading, setDltSupLoading] = useState(false);

  const [dltSubLoading, setDltSubLoading] = useState(false);

  // modal
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");

  // errors
  const [nameError, setNameError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  // check if email valid
  const isValidEmail = (email) => {
    const emailRegExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegExp.test(email);
  };

  // create supplier
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      setNameError("זהו שדה חובה");
    }
    if (!phone) {
      setPhoneError("זהו שדה חובה");
    }
    if (!address) {
      setAddressError("זהו שדה חובה");
    }
    if (!email) {
      setEmailError("זהו שדה חובה");
    }

    if (!isValidEmail(email)) {
      setEmailError("אימייל שגוי");
    }

    if (phone.length < 10) {
      setPhoneError("מספר הטלפון לא תקין");
    }

    if (
      name &&
      email &&
      phone &&
      address &&
      isValidEmail(email) &&
      phone.length >= 10
    ) {
      const payload = {
        name,
        phone,
        email,
        address,
        // status,
      };

      try {
        const res = await SUPPLIERS.create(payload);
        toast.success(res.data.message);
        setCreateSuccess(true);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
      setIsModalOpen(false);
    }
  };

  // FETCH STATS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await STATS.get();
        setStats(res.result);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        isTokenExpired(
          error.response.data.status,
          error.response.data.message,
          navigate
        );
      }
    };

    fetchData();
  }, []);

  // FETCH SUPPLIERS
  useEffect(() => {
    setCreateSuccess(null);
    setDeleteSuccess(null);
    const fetchData = async () => {
      try {
        const res = await SUPPLIERS.get(1, 6);

        if (res.result.length === 0) {
          setSuppliers([]);
        } else {
          let sortedItems = res.result.records.sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          setSuppliers(sortedItems);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        console.log(error.response.status);
        isTokenExpired(
          error.response.data.status,
          error.response.data.message,
          navigate
        );
      }
    };

    fetchData();
  }, [createSuccess, deleteSuccess]);

  // FETCH SUBSCRIBERS
  useEffect(() => {
    setDeleteSuccess(null);
    const fetchData = async () => {
      try {
        const res = await SUBSCRIBERS.get(1, 6);
        console.log(res.result.records.length, "+++++++");
        if (res.result.length === 0) {
          setSubscribers([]);
        } else {
          let sortedItems = res.result.records.sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          setSubscribers(sortedItems);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        console.log(error.response.status);
        isTokenExpired(
          error.response.data.status,
          error.response.data.message,
          navigate
        );
      }
    };

    fetchData();
  }, [deleteSuccess]);

  // DELETE SUPPLIER
  const deleteSupplier = async (id) => {
    formdata.append("id", id);
    formdata.append("isDeleted", true);

    setDltSupLoading(true);
    try {
      const res = await SUPPLIERS.update(formdata);
      toast.success(res.data.message);
      setDeleteSuccess(true);
      setIsSupDltModalOpen(false)
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setDltSupLoading(false);
  };

  // DELETE SUBSCRIBER
  const deleteSubscriber = async (id) => {
    formdata.append("id", id);
    formdata.append("isDeleted", true);

    setDltSubLoading(true);
    try {
      const res = await SUBSCRIBERS.update(formdata);
      toast.success(res.data.message);
      setDeleteSuccess(true);
      setIsSubDltModalOpen(false)
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setDltSubLoading(false);
  };

  return (
    <Layout>
      <div className={styles.container}>
        {/* STATISTICS */}
        <Stats stats={stats} />

        <div
          style={{
            marginBottom: "50px",
          }}
        >
          {/* SUBSCRIBERS */}
          {!subscribers ? (
            <Loader />
          ) : (
            <Table
              title="רשימת מנויים"
              exportBtnText="ייצוא"
              more="לכל המנויים"
              morePath={paths.subscribers}
              style={{
                marginBottom: "50px",
              }}
            >
              <TableHead data={SUBSCRIBERS_TABLE_HEAD} />
              <tbody>
                {subscribers.length > 0 ? (
                  subscribers.map((row) => (
                    <TableRowSubscriber
                      key={row._id}
                      row={row}
                      setIsSubDltModalOpen={setIsSubDltModalOpen}
                    />
                  ))
                ) : (
                  <tr className={styles.emptyRow}>
                    <td colSpan={7}>לא נמצאו רשומות</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </div>

        {/* SUPPLIERS */}
        {!suppliers ? (
          <Loader />
        ) : (
          <Table
            mb={2}
            title="רשימת ספקים"
            buttonText="הוספת ספק"
            exportBtnText="ייצוא"
            more="לכל הספקים"
            morePath={paths.suppliers}
            setIsModalOpen={setIsModalOpen}
          >
            <TableHead data={SUPPLIER_TABLE_HEAD} />
            <tbody>
              {suppliers.length > 0 ? (
                suppliers.map((row) => (
                  <TableRowSupplier
                    key={row._id}
                    row={row}
                    setIsSupDltModalOpen={setIsSupDltModalOpen}
                  />
                ))
              ) : (
                <tr className={styles.emptyRow}>
                  <td colSpan={7}>לא נמצאו רשומות</td>
                </tr>
              )}
            </tbody>
          </Table>
        )}

        {/* MODAL */}
        {isModalOpen && (
          <Modal setIsOpen={setIsModalOpen}>
            <div className={styles.modalContent}>
              <h3>הוספת ספק</h3>
              <form onSubmit={handleSubmit}>
                <div className={styles.field}>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setNameError("");
                      setPhoneError("");
                      setEmailError("");
                      setAddressError("");
                      setName(e.target.value);
                    }}
                    placeholder="שם הספק"
                  />
                  {nameError && (
                    <small className={styles.error}>{nameError}</small>
                  )}
                </div>
                <div className={styles.field}>
                  <input
                    type="number"
                    value={phone}
                    onChange={(e) => {
                      setNameError("");
                      setPhoneError("");
                      setEmailError("");
                      setAddressError("");
                      setPhone(e.target.value);
                    }}
                    placeholder="טלפון"
                  />
                  {phoneError && (
                    <small className={styles.error}>{phoneError}</small>
                  )}
                </div>
                <div className={styles.field}>
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => {
                      setNameError("");
                      setPhoneError("");
                      setEmailError("");
                      setAddressError("");
                      setAddress(e.target.value);
                    }}
                    placeholder="כתובת"
                  />
                  {addressError && (
                    <small className={styles.error}>{addressError}</small>
                  )}
                </div>
                <div className={styles.field}>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setNameError("");
                      setPhoneError("");
                      setEmailError("");
                      setAddressError("");
                      setEmail(e.target.value);
                    }}
                    placeholder="מייל"
                  />
                  {emailError && (
                    <small className={styles.error}>{emailError}</small>
                  )}
                </div>
                {/* <select
                  name="סטטוס"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="סטטוס" disabled>
                    סטטוס
                  </option>
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="INACTIVE">INACTIVE</option>
                </select> */}
                <button type="submit">
                  <img src="/assets/icons/plus.svg" alt="add" />
                  הוספת ספק
                </button>
              </form>
            </div>
          </Modal>
        )}

        {/* DELETE SUPPLIER MODAL */}
        {isSupDltModalOpen && (
          <Modal setIsOpen={setIsSupDltModalOpen}>
            <div className={styles.modalContainer}>
              <h1>האם אתה בטוח?</h1>
              <p>אתה בטוח שאתה רוצה למחוק את המשתמש?</p>

              <div className={styles.btnContainer}>
                <button onClick={() => setIsSupDltModalOpen(false)}>לא</button>
                <button onClick={() => deleteSupplier(isSupDltModalOpen)}>
                  {dltSupLoading ? <Spinner /> : "כן"}
                </button>
              </div>
            </div>
          </Modal>
        )}

        {/* DELETE SUBSCRIBER MODAL */}
        {isSubDltModalOpen && (
          <Modal setIsOpen={setIsSubDltModalOpen}>
            <div className={styles.modalContainer}>
              <h1>האם אתה בטוח?</h1>
              <p>אתה בטוח שאתה רוצה למחוק את המשתמש?</p>

              <div className={styles.btnContainer}>
                <button onClick={() => setIsSubDltModalOpen(false)}>לא</button>
                <button onClick={() => deleteSubscriber(isSubDltModalOpen)}>
                  {dltSubLoading ? <Spinner /> : "כן"}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default Main;
