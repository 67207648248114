import Label from "../../../components/ui/Label";

const TableRowSupplier = ({ row }) => {
  const { id, name, status } = row;

  return (
    <tr>
      <td style={{ width: "100px" }}>{name}</td>
      <td style={{ width: "50px" }}>
        <Label text={status} />
      </td>
      <td style={{ width: "80px" }}>
        <img src="/assets/icons/trash.svg" alt="delete" />
      </td>
    </tr>
  );
};

export default TableRowSupplier;
