import { useState, useEffect } from "react";
import Card from "../../../components/cards/Card";
import MANUFACTURERS from "../../../api/services/MANUFACTURERS";
import SUPPLIERS from "../../../api/services/SUPPLIERS";
import Spinner from "../../../components/ui/Spinner";
import Loader from "../../../components/ui/Loader";
import { toast } from "react-toastify";
import styles from "../../../styles/_features/supplierCard/components/addManufacturers.module.scss";

const AddManufacturers = ({ supplierID, tab }) => {
  const [loading, setLoading] = useState(false);
  const [manufacturers, setManufacturers] = useState();
  const [supplierManufacturers, setSupplierManufacturers] = useState([]);
  const [apiResponse, setApiResponse] = useState(false);

  // FETCH MANUFACTURERS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await MANUFACTURERS.get(tab);
        setManufacturers(res.result.manufacturers);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // FETCH SUPPLIER MANUFACTURERS
  useEffect(() => {
    setApiResponse(false);
    const fetchData = async () => {
      try {
        const res = await SUPPLIERS.get_supplier_manufacturers(supplierID, tab);
        setSupplierManufacturers(res.result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [tab, apiResponse]);

  // ADD MANUFACTURER
  const handleClick = async () => {
    const payload = {
      supplierId: supplierID,
      vehicle: tab,
      manufacturerIds: supplierManufacturers.map((i) => i.manufacturerId._id),
    };

    setLoading(true);
    try {
      const res = await SUPPLIERS.create_supplier_manufacturer(payload);
      toast.success(res.data.message);
      setApiResponse(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  if (!manufacturers) {
    return (
      <Card mb={3}>
        <div className={styles.container}>
          <Loader />
        </div>
      </Card>
    );
  }

  return (
    <Card mb={3}>
      <div className={styles.container}>
        <h3>שיוך יצרנים לספק:</h3>

        <div
          className={styles.assignAll}
          onClick={() => {
            if (supplierManufacturers.length === manufacturers.length) {
              setSupplierManufacturers([]);
            } else {
              setSupplierManufacturers(
                manufacturers.map((i) => {
                  return {
                    manufacturerId: {
                      _id: i.id,
                    },
                  };
                })
              );
            }
          }}
        >
          <input
            type="checkbox"
            checked={supplierManufacturers.length === manufacturers.length}
          />
          <p>שיוך כל היצרנים</p>
        </div>
        <ul className={styles.manufacturers}>
          {manufacturers?.map((i) => (
            <li
              key={i.id}
              onClick={() => {
                // if manufacturer exists then remove
                if (
                  supplierManufacturers.some(
                    (x) => x.manufacturerId._id === i.id
                  )
                ) {
                  setSupplierManufacturers(
                    supplierManufacturers.filter(
                      (j) => j.manufacturerId._id !== i.id
                    )
                  );
                }
                // else add it
                else {
                  setSupplierManufacturers((prev) => [
                    ...prev,
                    { manufacturerId: { _id: i.id } },
                  ]);
                }
              }}
            >
              <input
                type="checkbox"
                name="checkbox"
                checked={supplierManufacturers.some(
                  (x) => x.manufacturerId._id === i.id
                )}
              />
              <p>{i.name}</p>
            </li>
          ))}
        </ul>
        <button type="submit" onClick={handleClick}>
          {loading ? <Spinner /> : "שיוך יצרן"}
        </button>
      </div>
    </Card>
  );
};

export default AddManufacturers;
