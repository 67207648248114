import { del, get, post, put } from "../client";
import {
  CREATE_MANUFACTURER,
  DELETE_MANUFACTURER,
  MANUFACTURERS,
  UPDATE_MANUFACTURER,
  UPDATE_MODEL,
} from "../routes";

export default {
  get: async (vehicle) => await get(MANUFACTURERS(vehicle)),

  create: async (data) => await post(CREATE_MANUFACTURER, data),

  update: async (data) => await put(UPDATE_MANUFACTURER, data),

  delete: async (id) => await del(DELETE_MANUFACTURER(id)),

  update_model: async (data) => await post(UPDATE_MODEL, data),
};
