import { del, get } from "../client";
import {
  DELETE_REQUEST_ALL_REQUESTS,
  GET_ALL_REQUESTS,
  GET_REPLIES,
  GET_REPLY_COUNT_ALL_REQUESTS,
} from "../routes";

export default {
  get: async (
    page,
    size,
    part,
    manufacturer,
    model,
    engineType,
    gearType,
    carLicenseNumber
  ) =>
    await get(
      GET_ALL_REQUESTS(
        page,
        size,
        part ? part : "",
        manufacturer ? manufacturer : "",
        model ? model : "",
        engineType ? engineType : "",
        gearType ? gearType : "",
        carLicenseNumber ? carLicenseNumber : ""
      )
    ),

  get_reply_count: async (id) => await get(GET_REPLY_COUNT_ALL_REQUESTS(id)),

  delete_request: async (id) => await del(DELETE_REQUEST_ALL_REQUESTS(id)),

  get_replies: async (id, page, size) => await get(GET_REPLIES(id, page, size)),
};
