export let ALL_SUPPLIERS = [];

for (let i = 1; i <= 20; i++) {
  ALL_SUPPLIERS.push({
    id: "451374140",
    name: "ישראל ישראלי",
    telephone: "052-1234567",
    address: "לורם איפסום 25/2 תל אביב",
    email: "lorem@ipsum.com",
    city: "תל אביב",
    status: "אושר",
    pause: true,
  });
}

export let ALL_SUBSCRIBERS = [];

for (let i = 1; i <= 20; i++) {
  ALL_SUBSCRIBERS.push({
    id: "451374140",
    name: "ישראל ישראלי",
    telephone: "052-1234567",
    searches: "23",
    email: "lorem@ipsum.com",
    status: "לא פעיל",
    pause: false,
  });
}
