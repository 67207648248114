import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SUBSCRIBERS from "../api/services/SUBSCRIBERS";
import Search from "../components/forms/Search";
import Table from "../components/table/Table";
import TableHead from "../components/table/TableHead";
import TableRowSubscriber from "../features/subscribers/components/TableRowSubscriber";
import Loader from "../components/ui/Loader";
import Layout from "../layouts/Layout";
import { ALL_SUBSCRIBERS } from "../mock";
import styles from "../styles/_screens/suppliers.module.scss";
import { isTokenExpired } from "../utils/isTokenExpired";
import { tr } from "date-fns/locale";
import Spinner from "../components/ui/Spinner";
import Modal from "../components/ui/Modal";

const Subscribers = () => {
  let formdata = new FormData();

  const navigate = useNavigate();

  const SUBSCRIBER_TABLE_HEAD = [
    // "#",
    "שם מנוי",
    "טלפון",
    "חיפושים",
    "מייל",
    "סטטוס",
    "סוג מנוי",
    "סוג לקוח",
    "נוצר בתאריך",
    "תאריך סיום חבילה",
    "השהייה",
    "",
  ];

  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(50);

  const [totalRecords, setTotalRecords] = useState(0);

  const [subscribers, setSubscribers] = useState();

  const [originalSubscribers, setOriginalSubscribers] = useState();

  const [isUpdated, setIsUpdated] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [deleteSuccess, setDeleteSuccess] = useState(null);

  const [filterValue, setFilterValue] = useState("all");

  const [isSubDltModalOpen, setIsSubDltModalOpen] = useState(false);

  const [dltSubLoading, setDltSubLoading] = useState(false);

  // fetch subscribers list
  useEffect(() => {
    setDeleteSuccess(null);
    const fetchData = async () => {
      try {
        const res = await SUBSCRIBERS.get(page, limit);

        setTotalRecords(res.result.totalSubscribers);
        setTotalSubscribers(res.result.totalSubscribers);

        if (res.result.length === 0) {
          console.log(res.result.length);
          setOriginalSubscribers([]);
        } else {
          let sortedItems = res.result.records.sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          setOriginalSubscribers(sortedItems);
          // setOriginalSubscribers(res.result.records);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        console.log(error.response.status);
        isTokenExpired(
          error.response.data.status,
          error.response.data.message,
          navigate
        );
      }
    };

    fetchData();
  }, [isUpdated, deleteSuccess, limit]);

  // filter
  useEffect(() => {
    if (originalSubscribers) {
      if (filterValue === "all") {
        setSubscribers(originalSubscribers);
      } else if (filterValue === "no-plan") {
        setSubscribers(originalSubscribers.filter((i) => !i.lastSubscription));
      } else if (filterValue === "active-plan") {
        setSubscribers(originalSubscribers.filter((i) => i.lastSubscription));
      }
    }
  }, [filterValue, originalSubscribers]);

  // search
  const searchSubmit = async (e) => {
    e.preventDefault();

    if (searchValue.trim()) {
      try {
        const res = await SUBSCRIBERS.search(searchValue);
        console.log(res.result.length, "+++++++");
        if (res.result.length === 0) {
          console.log(res.result.length, "+++++++");
          setSubscribers([]);
        } else {
          setSubscribers(res.result.records);
        }
        setSearchValue("");
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        console.log(error.response.status);
        isTokenExpired(
          error.response.data.status,
          error.response.data.message,
          navigate
        );
      }
    }
  };
  const [totalSubscribers, setTotalSubscribers] = useState(0);

  // delete subscriber
  const deleteSubscriber = async (id) => {
    formdata.append("id", id);
    formdata.append("isDeleted", true);

    setDltSubLoading(true);
    try {
      const res = await SUBSCRIBERS.update(formdata);
      toast.success(res.data.message);
      setDeleteSuccess(true);
      setIsSubDltModalOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setDltSubLoading(false);
  };

  if (!subscribers) {
    return (
      <Layout>
        <div className={styles.container}>
          <Loader />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        {/* SEARCH */}
        <Search
          placeholder="חיפוש מנוי"
          mb={2}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          handleSubmit={searchSubmit}
        />

        {/* FILTER */}
        <div className={styles.filterContainer}>
          <select
            name="filter"
            onChange={(e) => setFilterValue(e.target.value)}
          >
            <option value="all" defaultValue={"all"}>
              כל המשתמשים
            </option>
            <option value="active-plan">מנויים פעילים</option>
            <option value="no-plan">מנויים ללא חבילה</option>
          </select>
        </div>

        {/* TABLE */}ß
        <Table mb={2} title={`רשימת מנויים (${totalSubscribers.toLocaleString()})`} exportBtnText="ייצוא">
          <TableHead data={SUBSCRIBER_TABLE_HEAD} />
          <tbody>
            {subscribers.length > 0 ? (
              subscribers.map((row) => (
                <TableRowSubscriber
                  key={row._id}
                  row={row}
                  setIsUpdated={setIsUpdated}
                  isUpdated={isUpdated}
                  setIsSubDltModalOpen={setIsSubDltModalOpen}
                />
              ))
            ) : (
              <tr className={styles.emptyRow}>
                <td colSpan={7}>לא נמצאו רשומות</td>
              </tr>
            )}
          </tbody>
        </Table>

        {limit < totalRecords && (
          <button
            className={styles.loadMore}
            onClick={() => setLimit((prev) => Number(prev) + 50)}
          >
            טען עוד
          </button>
        )}

        {/* DELETE SUBSCRIBER MODAL */}
        {isSubDltModalOpen && (
          <Modal setIsOpen={setIsSubDltModalOpen}>
            <div className={styles.modalContainer}>
              <h1>האם אתה בטוח?</h1>
              <p>אתה בטוח שאתה רוצה למחוק את המשתמש?</p>

              <div className={styles.btnContainer}>
                <button onClick={() => setIsSubDltModalOpen(false)}>לא</button>
                <button onClick={() => deleteSubscriber(isSubDltModalOpen)}>
                  {dltSubLoading ? <Spinner /> : "כן"}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default Subscribers;
