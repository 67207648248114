import { get, post, put } from "../client";
import {
  SUPPLIERS,
  CREATE_SUPPLIER,
  UPDATE_SUPPLIER,
  SEARCH_SUPPLIER,
  SUPPLIER_BY_ID,
  SUPPLIER_COMMENTS,
  CREATE_SUPPLIER_COMMENT,
  SUPPLIER_PARTS,
  CREATE_SUPPLIER_PARTS,
  GET_SUPPLIER_PARTS,
  GET_SUPPLIER_MANUFACTURERS,
  CREATE_SUPPLIER_MANUFACTURER,
  REPLY_REQUEST,
} from "../routes";

export default {
  get: async (page, size, name) =>
    await get(SUPPLIERS(page, size, name ? name : "")),

  get_by_id: async (id) => await get(SUPPLIER_BY_ID(id)),

  create: async (data) => await post(CREATE_SUPPLIER, data),

  update: async (data) => await put(UPDATE_SUPPLIER, data),

  search: async (name) => await get(SEARCH_SUPPLIER(name)),

  get_comments: async (id) => await get(SUPPLIER_COMMENTS(id)),

  create_comment: async (data) => await post(CREATE_SUPPLIER_COMMENT, data),

  supplier_parts: async (supplier, category) =>
    await get(SUPPLIER_PARTS(supplier, category)),

  create_supplier_parts: async (data) =>
    await post(CREATE_SUPPLIER_PARTS, data),

  get_supplier_parts: async (id) => await get(GET_SUPPLIER_PARTS(id)),

  get_supplier_manufacturers: async (id, vehicle) =>
    await get(GET_SUPPLIER_MANUFACTURERS(id, vehicle)),

  create_supplier_manufacturer: async (data) =>
    await post(CREATE_SUPPLIER_MANUFACTURER, data),

  reply_request: async (data) => await post(REPLY_REQUEST, data),
};
