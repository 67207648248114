import StatsCard from "../../features/main/components/StatsCard";
import styles from "../../styles/_containers/main/stats.module.scss";

const Stats = ({ stats }) => {
  return (
    <div className={styles.container}>
      <StatsCard
        id={1}
        title="ספקים פעילים"
        value={stats && stats.totalActiveSuppliers}
        more="לרשימה המלאה"
        img="/assets/icons/suppliers.svg"
      />

      <StatsCard
        id={2}
        title="לקוחות פעילים"
        value={stats && stats.totalActiveSubscribers}
        more="לרשימה המלאה"
        img="/assets/icons/clients.svg"
      />

      <StatsCard
        id={3}
        title="הכנסות מתחילת החודש"
        value={stats && `₪${stats.incomeOfTheMonth}`}
        img="/assets/icons/income.svg"
      />
    </div>
  );
};

export default Stats;
