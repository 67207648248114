import styles from "../../styles/_components/modal.module.scss";

const style = {
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: "0px",
  top: "0px",
  opacity: 0.45,
  background: "#000000 0% 0% no-repeat padding-box",
};

const Backdrop = ({ setIsOpen }) => (
  <div style={style} onClick={() => setIsOpen(false)}></div>
);

const Modal = ({ children, setIsOpen }) => {
  return (
    <div className={styles.mainContainer}>
      <Backdrop setIsOpen={setIsOpen} />
      <div className={styles.container}>
        <span className={styles.close} onClick={() => setIsOpen(false)}>
          X
        </span>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
