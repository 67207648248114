import { del, get, post, put } from "../client";
import {
  CATEGORIES,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
} from "../routes";

export default {
  get: async () => await get(CATEGORIES),

  create: async (data) => await post(CREATE_CATEGORY, data),

  delete: async (id) => await del(DELETE_CATEGORY(id)),

  update: async (data) => await put(UPDATE_CATEGORY, data),
};
