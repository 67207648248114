export const paths = {
  main: "/dashboard/",
  suppliers: "/dashboard/suppliers",
  subscribers: "/dashboard/subscribers",
  
  supplierCard: "/dashboard/supplier-card/:id",
  subscriberCard: "/dashboard/subscriber-card/:id",

  partsList: "/dashboard/parts-list",

  modelsList: "/dashboard/models-list",

  subModelsList: "/dashboard/sub-models-list",

  manufacturers: "/dashboard/manufacturers",

  editText: "/dashboard/edit-content",

  allRequests: "/dashboard/all-requests",
  
  login: "/auth/login",
  register: "/auth/register",

};
