import { useNavigate } from "react-router-dom";
import Card from "../cards/Card";
import { BASE_URL } from "../../config"
import styles from "../../styles/_components/table.module.scss";

const Table = ({
  children,
  title,
  buttonText,
  exportBtnText,
  more,
  morePath,
  mb,
  setIsModalOpen,
}) => {
  const navigate = useNavigate();
  return (
    <Card mb={mb}>
      <div className={styles.main}>
        {/* HEAD */}
        <div className={styles.header}>
          <h2>{title}</h2> 

          <div className={styles.btnContainer}>
            {exportBtnText && (
              <a href={`${BASE_URL}/admin/export?type=${title.startsWith("רשימת ספקים") ? "suppliers" : "subscribers"}`}>
                <button>
                  {exportBtnText}
                </button>
              </a>
            )}
            {buttonText && (
              <button onClick={() => setIsModalOpen(true)}>
                <img src="/assets/icons/plus.svg" alt="add" />
                {buttonText}
              </button>
            )}
          </div>
        </div>

        {/* TABLE */}
        <table>{children}</table>

        {/* MORE */}
        {more && (
          <p className={styles.more} onClick={() => navigate(morePath)}>
            {more}
          </p>
        )}
      </div>
    </Card>
  );
};

export default Table;
