import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MODELS from "../api/services/MODELS";
import Table from "../components/table/Table";
import TableHead from "../components/table/TableHead";
import Modal from "../components/ui/Modal";
import Spinner from "../components/ui/Spinner";
import TableRowSupplier from "../features/models/components/TableRowSupplier";
import Layout from "../layouts/Layout";
import styles from "../styles/_screens/models.module.scss";
import { isTokenExpired } from "../utils/isTokenExpired";

const Models = () => {
  let formData = new FormData();
  const navigate = useNavigate();
  const MODEL_TABLE_HEAD = ["שם", "תיאור", "סטטוס", ""];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [models, setModels] = useState();

  const [name, setName] = useState("");
  const [modelIcon, setModelIcon] = useState("");
  const [modelIconPreview, setModelIconPreview] = useState("");
  const [description, setDescription] = useState("");

  const [fetchLoading, setFetchLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);

  // fetch models list
  useEffect(() => {
    setCreateSuccess(false);
    const fetchData = async () => {
      try {
        const res = await MODELS.get();

        if (res.result.length === 0) {
          setModels([]);
        } else {
          setModels(res.result);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        isTokenExpired(error.response.data.status, error.response.data.message, navigate);
      }
    };

    fetchData();
  }, [createSuccess]);

  const addModel = async (e) => {
    e.preventDefault();

    formData.append("name", name);
    formData.append("modelIcon", modelIcon);
    formData.append("description", description);

    setCreateLoading(true);
    try {
      const res = await MODELS.create(formData);
      toast.success(res.data.message);
      setCreateSuccess(true);
      setIsModalOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setCreateLoading(true);
  };

  if (!models) {
    return (
      <Layout>
        <div className={styles.container}>loading...</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        {/* TABLE */}
        <Table
          mb={2}
          title="דגם"
          buttonText="דגם"
          setIsModalOpen={setIsModalOpen}
        >
          <TableHead data={MODEL_TABLE_HEAD} />
          <tbody>
            {models.length !== 0 &&
              models.map((row) => <TableRowSupplier key={row.id} row={row} />)}
          </tbody>
        </Table>

        {/* MODAL */}
        {isModalOpen && (
          <Modal setIsOpen={setIsModalOpen}>
            <div className={styles.modalContent}>
              <h3>דגם</h3>
              <form onSubmit={addModel}>
                <input
                  type="file"
                  className={styles.file}
                  name="modelIcon"
                  placeholder="אייקון של הדגם"
                  onChange={(e) => {
                    setModelIcon(e.target.files[0]);
                    setModelIconPreview(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                <div className={styles.upload}>
                  <p>אייקון של הדגם</p>
                  <span>+</span>
                </div>
                {modelIconPreview && (
                  <img
                    src={modelIconPreview}
                    alt="preview"
                    className={styles.preview}
                  />
                )}
                <input
                  type="text"
                  name="name"
                  placeholder="שם"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="text"
                  name="description"
                  placeholder="תיאור"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <button type="submit">
                  {createLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <img src="/assets/icons/plus.svg" alt="add" />
                      הוספת ספק
                    </>
                  )}
                </button>
              </form>
            </div>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default Models;
