import { useState } from "react";
import { toast } from "react-toastify";
import MANUFACTURERS from "../../api/services/MANUFACTURERS";
import Spinner from "../../components/ui/Spinner";
import styles from "../../styles/_containers/_manufacturer/manufacturers.module.scss";

const Models = ({ models, setModels, manufacturer }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const [editValue, setEditValue] = useState(null);
  const [isEdit, setIsEdit] = useState("");

  // CREATE MODEL
  const createModel = async (e) => {
    e.preventDefault();

    const payload = {
      manufacturerId: manufacturer.id,
      models: [...models, name],
    };

    setLoading(true);
    try {
      const res = await MANUFACTURERS.update_model(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        setModels([...models, name]);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  // UPDATE MODEL
  const updateModel = async (id) => {
    const payload = {
      manufacturerId: manufacturer.id,
      models: models.map((i, idx) => {
        if (idx === id) {
          return editValue;
        } else {
          return i;
        }
      }),
    };

    try {
      const res = await MANUFACTURERS.update_model(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        setIsEdit(null);
        setEditValue(null);
        setModels(
          models.map((i, idx) => {
            if (idx === id) {
              return editValue;
            } else {
              return i;
            }
          })
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  // DELETE MODEL
  const deleteModel = async (id) => {
    const payload = {
      manufacturerId: manufacturer.id,
      models: models.filter((x, idx) => idx !== id),
    };

    try {
      const res = await MANUFACTURERS.update_model(payload);
      if (res.status === 200) {
        setIsEdit(null);
        setEditValue(null);
        toast.success(res.data.message);
        setModels(models.filter((x, idx) => idx !== id));
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>דגמים</p>

      {/* add model */}
      <form onSubmit={createModel}>
        <input
          type="text"
          placeholder="הזן שם"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button type="submit">
          {loading ? (
            <div className={styles.spinner}>
              <Spinner />
            </div>
          ) : (
            <>
              <img src="/assets/icons/plus.svg" alt="add" />
              הוספה
            </>
          )}
        </button>
      </form>

      {/* list of models */}
      <h4>רשימת דגמים</h4>
      <div className={styles.list}>
        {models.length > 0 &&
          models.map((i, idx) => (
            <Item
              key={idx}
              idx={idx}
              name={i}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              editValue={editValue}
              setEditValue={setEditValue}
              updateModel={updateModel}
              deleteModel={deleteModel}
            />
          ))}
      </div>
    </div>
  );
};

export default Models;

const Item = ({
  idx,
  name,
  isEdit,
  setIsEdit,
  editValue,
  setEditValue,
  updateModel,
  deleteModel,
}) => {
  return (
    <div className={styles.item} key={idx}>
      {
        // if edit true then show input field
        isEdit === idx ? (
          <input
            className={styles.editField}
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
        ) : (
          // if edit false then show the item name
          <div className={`${styles.box}`}>
            <span>{">"}</span>
            <p>{name}</p>
          </div>
        )
      }

      {/* delete icon */}
      <img
        src="/assets/icons/trash.svg"
        alt="delete"
        className={styles.delete}
        onClick={() => deleteModel(idx)}
      />

      {/* if edit true then show save icon */}
      {isEdit === idx ? (
        <img
          src="/assets/icons/save.png"
          alt="edit"
          className={styles.save}
          onClick={() => updateModel(idx)}
        />
      ) : (
        // if edit false then show edit icon
        <img
          src="/assets/icons/write.svg"
          alt="edit"
          className={styles.edit}
          onClick={() => {
            setIsEdit(idx);
            setEditValue(name);
          }}
        />
      )}
    </div>
  );
};
