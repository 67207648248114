import { useState, useEffect } from "react";
import { format } from "date-fns";
import REQUESTS from "../../../api/services/REQUESTS";
import { toast } from "react-toastify";
import SelectSupplier from "./selectSupplier";
import SUPPLIERS from "../../../api/services/SUPPLIERS";
import { useLicenseSearch } from "../../../hooks/useLicenseSearch";

const TableRowAllRequests = ({ row, requests }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [supplier, setSupplier] = useState(null);
  const [comment, setComment] = useState("");
  const [price, setPrice] = useState("");

  const [replies, setReplies] = useState([]);

  const {
    _id,
    createdAt,
    subscriberId,
    carLicenseNumber,
    engineType,
    customPartName,
    part,
    manufacturer,
    model,
    hp,
    yop,
    gearType,

    setDeleteRequestSuccess,
  } = row;

  const [count, setCount] = useState(0);
  function translateSubscriberType(type) {
    switch (type) {
      case "GARAGE":
        return "מוסך";
      case "PRIVATE":
        return "פרטי";
      case "LEASING_COMPANY":
        return "ליסינג";
      default:
        return type; // return the original type if it's none of the above
    }
  }
  // fetch reply count
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await REQUESTS.get_reply_count(_id);
        setCount(res.result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // fetch replies
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await REQUESTS.get_replies(_id, "", 50);
        setReplies(res.result.replies);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // delete
  const deleteReq = async () => {
    try {
      const res = await REQUESTS.delete_request(_id);
      setDeleteRequestSuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  // reply
  const handleReply = async (status) => {
    let payload = {
      supplierId: supplier._id,
      request: _id,
      comment,
    };

    if (status === "ACCEPT") {
      payload.price = price;
      payload.status = "ACCEPT_WITH_PRICE";
    }

    if (status === "DELETE") {
      payload.status = "DELETE";
    }

    try {
      const res = await SUPPLIERS.reply_request(payload);
      toast.success(res.data.message);
      setIsOpen(false);
      setSupplier(null);
      setComment("");
      setPrice("");
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

  // call govt license api
  const { searchForLicense, licenseLoading } = useLicenseSearch();

  const [licenseDataToSend, setLicenseDataToSend] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await searchForLicense(carLicenseNumber);
        if (response.success) {
          setLicenseDataToSend(response.data);
        } else {
          setLicenseDataToSend({});
          toast.error(response.message);
        }
      } catch (e) {
        console.log("Error while searching license. Reason:", e);
      }
    };

    fetchData();
  }, [requests]);

  return (
    <>
      <tr>
        {/* <td style={{ width: "50px" }}>{_id}</td> */}

        {/* date */}
        <td>{format(new Date(createdAt), "dd.MM.yyyy - HH:mm:ss")}</td>
        {/* vehicle part */}
        <td>
          {customPartName}
          {part?.name}
        </td>
        {/* type */}
        <td>{subscriberId?.subscriberType}</td>

        {/* name + phone */}
        <td style={{padding:"0px 10px"}}>
          {subscriberId?.name}
          <br />
          <a href={`tel:${subscriberId?.phone}`}>{subscriberId?.phone}</a>
        </td>

        {/* city */}
        <td>{subscriberId?.city}</td>

        {/* vehicle number */}
        <td>{carLicenseNumber}</td>

        {/* degem manoa */}
        <td style={{padding:"0px 20px"}}>
          {carLicenseNumber &&
            licenseDataToSend &&
            Number(carLicenseNumber) ===
              Number(licenseDataToSend.mispar_rechev) && (
              <p>{licenseDataToSend?.degem_manoa}</p>
            )}
        </td>

        {/* engine type */}
        <td style={{padding:"0px 10px"}}>{engineType}</td>

        {/* part */}
        {/* <td></td> */}

        {/* manufacturer */}
        <td>{manufacturer}</td>

        {/* model */}
        <td>{model}</td>

        {/* yop */}
        <td>{yop}</td>

        {/* reply count */}
        <td style={{ textAlign: "center" }}>{count}</td>

        {/* type */}
        <td style={{ textAlign: "center" }}>
          {translateSubscriberType(subscriberId?.subscriberType)}
        </td>

        {/* delete */}
        <td
          style={{ cursor: "pointer", textAlign: "center" }}
          onClick={deleteReq}
        >
          <img src="/assets/icons/trash_2.svg" alt="delete" />
        </td>

        <td style={{ width: "90px" }}>
          {isOpen ? (
            <button onClick={() => setIsOpen(false)}>X</button>
          ) : (
            <button
              onClick={() => {
                if (!isOpen2) {
                  setIsOpen(true);
                }
              }}
            >
              הגב כספק
            </button>
          )}
        </td>

        <td>
          {count !== 0 ? (
            <>
              {isOpen2 ? (
                <button onClick={() => setIsOpen2(false)}>X</button>
              ) : (
                <button
                  onClick={() => {
                    if (!isOpen) {
                      setIsOpen2(true);
                    }
                  }}
                  style={{ width: "120px" }}
                >
                  תשובות מספקים
                </button>
              )}
            </>
          ) : (
            ""
          )}
        </td>

        {/* gear */}
        {/* <td>{gearType}</td> */}

        {/* hp */}
        {/* <td style={{ textAlign: "right" }}>{hp}</td> */}
      </tr>
      {isOpen && (
        <tr>
          <td
            colSpan={15}
            style={{
              padding: "20px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "12px",
              }}
            >
              {/* SUPPLIERS FILTER */}
              <SelectSupplier supplier={supplier} setSupplier={setSupplier} />
              {/* COMMENT */}
              <input
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="תגובה"
              />
              {/* PRICE */}
              <input
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder="מחיר"
              />

              <button
                style={{
                  width: "100px",
                  height: "45px",
                }}
                disabled={!supplier}
                onClick={() => {
                  handleReply("ACCEPT");
                }}
              >
                שלח תגובה
              </button>

              <button
                style={{
                  width: "100px",
                  height: "45px",
                  backgroundColor: "#D22B2B",
                }}
                disabled={!supplier}
                onClick={() => {
                  handleReply("DELETE");
                }}
              >
                מחיקה
              </button>
            </div>
          </td>
        </tr>
      )}

      {isOpen2 && (
        <tr>
          <td
            colSpan={15}
            style={{
              padding: "20px 10px",
            }}
          >
            <table>
              <thead>
                <tr>
                  {/* supplier name */}
                  <th style={{ backgroundColor: "#eaeef0" }}>שם ספק</th>
                  {/* supplier phone */}
                  <th style={{ backgroundColor: "#eaeef0" }}>טלפון</th>
                  {/* price */}
                  <th style={{ backgroundColor: "#eaeef0" }}>מחיר</th>
                  {/* comment */}
                  <th style={{ backgroundColor: "#eaeef0" }}>תגובה</th>
                  {/* status */}
                  <th style={{ backgroundColor: "#eaeef0" }}>סטטוס</th>
                  {/* created at */}
                  <th style={{ backgroundColor: "#eaeef0" }}>תגובה בתאריך</th>
                </tr>
              </thead>
              <tbody>
                {replies?.length > 0
                  ? replies.map((j) => (
                      <tr key={j._id}>
                        <td>{j.supplier?.name}</td>
                        <td>{j.supplier?.phone ? j.supplier?.phone : "-"}</td>
                        <td>{j.price ? j.price : "-"}</td>
                        <td>{j.comment ? j.comment : "-"}</td>
                        <td>
                          {j.status === "ACCEPT_WITH_PRICE"
                            ? "נשלח מחיר"
                            : "נשלח לארכיון"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {format(new Date(j.createdAt), "dd.MM.yyyy")}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRowAllRequests;
