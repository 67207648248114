import { useNavigate } from "react-router-dom";
import Label from "../../../components/ui/Label";

const TableRowSupplier = ({ row, setIsSupDltModalOpen }) => {
  const navigate = useNavigate();

  const { _id, name, phone, address, email, city, status } = row;

  return (
    <tr>
      {/* <td style={{ width: "50px" }}>{_id}</td> */}
      <td style={{ width: "100px" }}>{name}</td>
      <td style={{ width: "100px" }}>{phone}</td>
      <td style={{ width: "150px" }}>{address}</td>
      <td style={{ width: "100px" }}>{email}</td>
      <td style={{ width: "50px" }}>
        <Label text={status} />
      </td>
      <td style={{ width: "80px" }}>
        <img
          src="/assets/icons/share.svg"
          alt="share"
          onClick={() => navigate(`/dashboard/supplier-card/${_id}`)}
        />
        <img
          src="/assets/icons/edit.svg"
          alt="edit"
          onClick={() => navigate(`/dashboard/supplier-card/${_id}`)}
        />
        <img
          src="/assets/icons/trash_2.svg"
          alt="delete"
          className="tableDeleteIcon"
          onClick={() => setIsSupDltModalOpen(_id)}
        />
      </td>
    </tr>
  );
};

export default TableRowSupplier;
