import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isTokenExpired } from "../../../utils/isTokenExpired";
import CATEGORIES from "../../../api/services/CATEGORIES";
import PARTS from "../../../api/services/PARTS";
import SUPPLIERS from "../../../api/services/SUPPLIERS";
import Spinner from "../../../components/ui/Spinner";
import Card from "../../../components/cards/Card";
import Loader from "../../../components/ui/Loader";
import Parts from "./Parts";
import Tabs from "../../partsList/components/Tabs";
import styles from "../../../styles/_features/supplierCard/components/partsCategories.module.scss";

const PartsCategories = ({ supplierID, tab, setTab }) => {
  const navigate = useNavigate();

  // == CATEGORIES ==

  const [categories, setCategories] = useState();

  const [filteredCategories, setFilteredCategories] = useState();

  const [category, setCategory] = useState();

  const [assignedCategories, setAssignedCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState();

  // == PARTS ===

  const [parts, setParts] = useState();

  const [assignedParts, setAssignedParts] = useState([]);

  // == OTHER ==

  const [loading1, setLoading1] = useState(false);

  const [unAssignSuccess, setUnAssignSuccess] = useState(null);

  const [selectAll, setSelectAll] = useState(false);

  // FETCH CATEGORIES
  useEffect(() => {
    setUnAssignSuccess(null);
    const fetchCategories = async () => {
      try {
        const res = await CATEGORIES.get();
        if (res.result.length === 0) {
          setCategories([]);
        } else {
          setCategories(res.result);
        }
      } catch (error) {
        console.log(error);
        isTokenExpired(
          error.response.data.status,
          error.response.data.message,
          navigate
        );
      }
    };

    fetchCategories();
  }, [unAssignSuccess]);

  // FILTER CATEGORIES BY VEHICLE
  useEffect(() => {
    if (categories) {
      const filtered = categories.filter((i) => i.vehicle === tab);
      setFilteredCategories(filtered);
    }
  }, [categories, tab, unAssignSuccess]);

  // FETCH PARTS
  useEffect(() => {
    if (category) {
      const fetchParts = async () => {
        try {
          const res = await PARTS.get(category);
          setParts(res.result);
        } catch (error) {
          console.log(error);
          setParts([]);
        }
      };

      fetchParts();
    } else {
      setParts([]);
    }
  }, [category, unAssignSuccess]);

  // ADD SUPPLIER PARTS
  const addSupplierParts = async () => {
    const payload = {
      supplierId: supplierID,
      categoryId: category,
      partIds: selectedCategory.parts.map((i) => i.id),
    };

    setLoading1(true);
    try {
      const res = await SUPPLIERS.create_supplier_parts(payload);
      toast.success(res.data.message);
      setUnAssignSuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading1(false);
  };

  // FETCH SUPPLIER'S PARTS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SUPPLIERS.get_supplier_parts(supplierID);

        // list categories that are assigned to supplier
        setAssignedCategories(res.result.map((i) => i.categoryId));

        // list of all parts that are assigned to supplier
        let spreadArray = [];
        for (let index = 0; index < res.result.length; index++) {
          const element = res.result[index];
          spreadArray.push(...element.parts.map((i) => i.partId));
        }
        setAssignedParts(spreadArray);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [unAssignSuccess]);

  // UNASSIGN SUPPLIER PART
  const handleUnAssign = async (id) => {
    const payload = {
      supplierId: supplierID,
      categoryId: id,
      partIds: [],
    };

    try {
      const res = await SUPPLIERS.create_supplier_parts(payload);
      toast.success(res.data.message);
      setUnAssignSuccess(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  if (!filteredCategories) {
    return (
      <Card mb={3}>
        <div className={styles.container}>
          <Loader />
        </div>
      </Card>
    );
  }

  return (
    <Card mb={3}>
      <div className={styles.container}>
        {/* TABS */}
        <Tabs tab={tab} setTab={setTab} setCategory={setCategory} />

        <h3>קטגוריות חלפים</h3>

        {/* CATEGORIES */}
        <div className={styles.categories}>
          {filteredCategories.length > 0
            ? filteredCategories.map((i, index) => (
                <div className={styles.cat}>
                  <div
                    className={`${styles.category} ${
                      assignedCategories.includes(i._id) ? styles.activeCat : ""
                    }`}
                    // on clicking the category, save the category id to the state and check if parts of that category exists in the assigned parts list
                    onClick={(e) => {
                      setSelectAll(false);
                      setCategory(i._id);
                      setSelectedCategory({
                        cat: i._id,
                        parts: assignedParts.filter(
                          (part) => part.categoryId === i._id
                        ),
                      });
                    }}
                  >
                    <p>{i._id === category ? <b>{i.name}</b> : i.name}</p>
                  </div>

                  {/* unassign */}
                  {assignedCategories.includes(i._id) && (
                    <div
                      className={`${styles.category} ${styles.unAssign}`}
                      onClick={() => handleUnAssign(i._id)}
                    >
                      <p>הסר שיוך קטגוריה</p>
                    </div>
                  )}
                </div>
              ))
            : null}
        </div>

        {/* PARTS */}
        {parts && parts.length > 0 && (
          <Parts
            parts={parts}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            category={category}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
          />
        )}

        {selectedCategory && selectedCategory.parts.length > 0 && (
          <button className={styles.submitParts} onClick={addSupplierParts}>
            {loading1 ? <Spinner /> : "שמור בחירה"}
          </button>
        )}
      </div>
    </Card>
  );
};

export default PartsCategories;
