import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import EditText from "../screens/EditText";
import Login from "../screens/Login";
import Main from "../screens/Main";
import Manufacturer from "../screens/Manufacturer";
import Models from "../screens/Models";
import PartsList from "../screens/PartsList";
import Register from "../screens/Register";
import SubModels from "../screens/SubModels";
import SubscriberCard from "../screens/SubscriberCard";
import Subscribers from "../screens/Subscribers";
import SupplierCard from "../screens/SupplierCard";
import Suppliers from "../screens/Suppliers";
import { paths } from "./paths";
import AllRequests from "../screens/AllRequests";

const AppRoutes = () => {
  const token = localStorage.getItem("authToken");

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            !token ? (
              <Navigate to="/dashboard/" replace />
            ) : (
              <Navigate to="/dashboard" replace />
            )
          }
        />

        <Route exact path={paths.login} element={<Login />} />

        <Route exact path={paths.register} element={<Register />} />

        <Route exact path={paths.main} element={<Main />} />

        <Route exact path={paths.suppliers} element={<Suppliers />} />

        <Route exact path={paths.subscribers} element={<Subscribers />} />

        <Route exact path={paths.supplierCard} element={<SupplierCard />} />

        <Route exact path={paths.subscriberCard} element={<SubscriberCard />} />

        <Route exact path={paths.partsList} element={<PartsList />} />

        <Route exact path={paths.modelsList} element={<Models />} />

        <Route exact path={paths.subModelsList} element={<SubModels />} />

        <Route exact path={paths.manufacturers} element={<Manufacturer />} />

        <Route exact path={paths.editText} element={<EditText />} />

        <Route exact path={paths.allRequests} element={<AllRequests />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
